<template>
  <div class="flex flex-col mb-24">
    <TabView ref="tabview1" v-model:activeIndex="activePanelIndex">
      <TabPanel header="Knowledgebase">
        <div class="mt-3"></div>
        <h4 v-if="isOrgUnitHead(loggedInUser)" class="text-md my-2 mt--10 font-semibold text-gray-500">Practice Group Accounts</h4>
        <Accordion v-if="isOrgUnitHead(loggedInUser)">
          <AccordionTab header="Adding Users">
            <ol class="mb-4 text-sm">
              <li>1. From the left hand menu click “Users.”</li>
              <li>2. Click “Add User.”</li>
              <li>3. If the User is new to Virtual Hallway, enter their information in the form and click “Join.” If the User already has an account, click "Add Existing User", enter their email address and click Add.</li>
              <li>4. The User will receive a confirmation email. Once they confirm, they will be added to your Practice Group.</li>
            </ol>
          </AccordionTab>
          <AccordionTab header="Sending a Consult Request on Behalf of a User">
            <ol class="mb-4 text-sm">
              <li>1. Click “Book A Consult.”</li>
              <li>2. From the drop down menu, select the Physician or Nurse Practitioner user you are sending a consult request for.</li>
              <li>3. Click on the specialty you would like to send a consult request to.</li>
            </ol>
          </AccordionTab>
          <AccordionTab header="Submit Verification on Behalf of a User">
            <ol class="mb-4 text-sm">
              <li>1. From the left hand menu click “Verification.”</li>
              <li>2. Select the user you are verifying from the drop down menu.</li>
              <li>3. Upload the user's photo ID by clicking “Upload”, select the file with a copy of their photo ID, and hit “Submit”.</li>
              <li>4. If the user is fee for service, they must log into their account to complete a billing arrangement form. Alternatively, you can download and print the form, have the end user sign the form and then upload it. The end user also must log into their account to sign the End User License Agreement. Once these steps are completed, they will be verified.</li>
            </ol>
          </AccordionTab>
          <AccordionTab header="Adding Availability on Behalf of a Consultant">
            <ol class="mb-4 text-sm">
              <li>1. From the left hand menu Click “Availability.”</li>
              <li>2. Select the user you are verifying from the drop down menu.</li>
              <li>3. To set a recurring weekly schedule of availability, select the plus sign next to the days the consultant is available.</li>
              <li>4. Enter the start and end times the consultant is available. Click Save Schedule.</li>
              <li>5. To set individual days rather than recurring schedule, click Add Day.</li>
              <li>6. Select the date and times the consultant is available. Click Save Schedule.</li>
              <li>7. If the consultant requires full days off from their recurring schedule, click Add Day under 'Holidays &amp; Days Off'. If they only require partial days off, you can still set hourly availability under 'Individual Daily Schedule'. These settings will override the recurring schedule.</li>
            </ol>
          </AccordionTab>
          <AccordionTab header="Cancelling a Consult on Behalf of a User">
            <ol class="mb-4 text-sm">
              <li>1. Locate the consult request. You can find upcoming consults in one of three places:</li>
              <ul class="ml-2 text-xs">
                <li>• In the My Calendar tab under the Upcoming Consults section</li>
                <li>• In the calendar itself at the booked date and time</li>
                <li>• In the My Consults tab</li>
              </ul>
              <li>2. Once you have located the consult, open it up.</li>
              <li>3. On the right hand side click Cancel Appointment.</li>
              <li>4. Enter the reason for the cancellation and then click Confirm Cancel Appointment.</li>
            </ol>
          </AccordionTab>
          <AccordionTab header="Rebooking a Cancelled Consult on Behalf of a User">
            <ol class="mb-4 text-sm">
              <li>1. Open the cancelled consult from the Users calendar.</li>
              <li>2. Click the Reschedule button</li>
              <li>3. Select the Date and Time for when you would like to reschedule.</li>
              <li>4. Click Confirm.</li>
              <li>5. Review the consult request form and make any additions or changes required, then click Submit.</li>
            </ol>
          </AccordionTab>
        </Accordion>
        <h4 class="text-md mb-2 mt-4 font-semibold text-gray-500">Consults</h4>
        <Accordion v-model:activeIndex="openedSupportSectionKnowledgebaseAccordionIndex">
          <AccordionTab header="Booking a Consult">
            <ol class="mb-4 text-sm">
              <li>1. Click Book a Consult in the top right of the login screen.</li>
              <li>2. Select the specialty you would like to consult with.</li>
              <li>3. Select:</li>
              <ul class="ml-2">
                <li>▪ "Book Next Availability" to schedule your consult for the nearest available time. If the time and date works for your schedule, select "Schedule a Call".</li>
                <li>▪ "View All Availabilities" to see all {{ getReferralPersonTerm(actingUser) }}s and their availability. Select the {{ getReferralPersonTerm(actingUser) }} you would like to consult with. Select a date and time for the consult, then click confirm.</li>
              </ul>
              <li>4. Complete the consult request form. If the {{ getReferralPersonTerm(actingUser) }} is available 'OnDemand' (represented by a lightning bolt icon), you will be able to select the checkbox at the bottom of the form to opt-in to a sooner callback if the {{ getReferralPersonTerm(actingUser) }} becomes available. If there is no checkbox, the {{ getReferralPersonTerm(actingUser) }} you are booking with is not available for 'OnDemand' consults.</li>
              <li>5. Click "Submit" to confirm your consult booking.</li>
            </ol>
            <p class="tip-title text-sm">Note: If you do not want to complete the consult request form immediately, you can click Save Draft instead of Submit. Keep in mind, the form <strong>must be completed by the specified time on the consult request</strong>, or the appointment time will be forfeited.</p>
          </AccordionTab>
          <AccordionTab header="Consult Statuses">
            <p class="mb-3 text-sm"><span class="Pending rounded px-2 py-1 text-xs font-bold uppercase text-white">Pending</span> - The consult appointment time has been reserved, but the booking is not confirmed until the consult request form is submitted.</p>
            <p class="mb-3 text-sm"><span class="Upcoming rounded px-2 py-1 text-xs font-bold uppercase text-white">Upcoming</span> - The consult request form was submitted and the booking is sent to the consulting practitioner to call at the scheduled time.</p>
            <p class="mb-3 text-sm"><span class="InReview rounded px-2 py-1 text-xs font-bold uppercase text-white">In Review</span> - The consult appointment has passed, and the referring practitioner is awaiting the consulting practitioner to submit the consult report.</p>
            <p class="mb-3 text-sm"><span class="Complete rounded px-2 py-1 text-xs font-bold uppercase text-white">Complete</span> - The consulting practitioner has submitted the consult report, but can still make addendums.</p>
            <p class="mb-3 text-sm"><span class="Cancelled rounded px-2 py-1 text-xs font-bold uppercase text-white">Cancelled</span> - The consult has either been:</p>
            <ul class="ml-6 mb-4 text-sm">
              <li>▪ Cancelled by the {{ getReferralPersonTerm(actingUser, true) }}</li>
              <li>▪ Cancelled by the Referring Practitioner</li>
              <li>▪ Cancelled by a Virtual Hallway Admin</li>
              <li>▪ The call was unable to be completed</li>
            </ul>
            <p class="tip-title text-sm">Note: Cancelled consults can be rescheduled/re-booked without the need to rewrite the consult form.</p>
          </AccordionTab>
          <AccordionTab header="Finding Your Consults">
            <p class="tip-title text-sm">Virtual Hallway securely stores the documentation of upcoming and past consults.</p>
            <div class="my-3">
              <p class="text-sm font-bold">Upcoming Consults</p>
              <ol class="text-sm">
                <li>1. Click My Consults.</li>
                <li>2. Consult records are sorted by Date in chronological order in the table. You may sort the information by selecting different table headings:</li>
                <ul class="ml-2">
                  <li>▪ Click Patient to sort patient names alphabetically</li>
                  <li>▪ Click {{ getReferralPersonTerm(actingUser, true) }} to sort {{ getReferralPersonTerm(actingUser) }} names alphabetically</li>
                  <li>▪ Click Date to sort in reverse chronological order</li>
                </ul>
                <li>3. To search for a specific consult you can enter search terms in the Search Box on the right hand side of the screen. You can search by patient name or {{ getReferralPersonTerm(actingUser) }} name.</li>
                <li>4. To search for a specific consult, you can enter the patient or practitioner name in the Search box on the right-hand side of the screen.</li>
              </ol>
            </div>
            <div class="mb-2">
              <p class="text-sm font-bold">Past Consults</p>
              <ol class="text-sm">
                <li>1. Click My Calendar in the sidebar.</li>
                <li>2. Scroll down below the calendar.</li>
                <li>3. Completed Consults are on the left and Upcoming Consults are on right-hand side.</li>
              </ol>
            </div>
            <p class="tip-title text-sm">Note: You can export your consults as PDFs to add to your records/EMR by clicking "Download" when a consult is selected.</p>
          </AccordionTab>
          <AccordionTab header="Cancelling a Booked Consult">
            <p class="tip-title text-sm">Sometimes we need to cancel or change a consult appointment on Virtual Hallway.</p>
            <div class="my-2">
              <p class="text-sm font-bold">To cancel a consult</p>
              <ol class="text-sm">
                <li>1. Locate the consult request. You can find upcoming consults in one of three places:</li>
                <ul class="ml-2">
                  <li>▪ In the My Calendar tab under the Upcoming Consults section</li>
                  <li>▪ In the calendar itself at the booked date and time</li>
                  <li>▪ In the My Consults tab</li>
                </ul>
                <li>2. Once you have located the consult, open it up.</li>
                <li>3. On the right hand side click Cancel Appointment.</li>
                <li>4. Select the reason for the cancellation and then click Confirm Cancellation.</li>
              </ol>
            </div>
            <p class="tip-title text-sm">Note: a notification will be sent out to the other party that the appointment has been cancelled. These appointments can easily be rescheduled.</p>
          </AccordionTab>
          <AccordionTab header="Rescheduling an Appointment">
            <p class="tip-title text-sm">Sometimes you, a {{ getReferralPersonTerm(actingUser) }}, or Virtual Hallway Admin might need to cancel an appointment.</p>
            <div class="my-3">
              <p class="text-sm font-bold">To rebook a cancelled consult:</p>
              <ol class="text-sm">
                <li>1. Open the cancelled consult from your calendar, or from the My Consults tab</li>
                <li>2. Click the Reschedule button</li>
                <li>3. Pick the {{ getReferralPersonTerm(actingUser) }} you would like to rebook the consult with.</li>
                <li>4. Select the Date and Time for when you would like to reschedule.</li>
                <li>5. Click Confirm.</li>
                <li>6. Review the consult request form and make any additions or changes required, then click Submit.</li>
              </ol>
            </div>
          </AccordionTab>
          <AccordionTab header="Scheduling Availability" v-if="isSpecialist(loggedInUser)">
            <p class="text-sm"></p>
            <div class="my-2">
              <p class="text-sm font-bold">To schedule time slots you are available to be booked for phone consults:</p>
              <ol class="text-sm">
                <li>1. Click My Availability</li>
                <li>2. To set a recurring weekly schedule of availability, select the plus sign next to the days you are available.</li>
                <ul class="ml-2">
                  <li>▪ Enter the start and end times you are available. Click Save Schedule.</li>
                </ul>
                <li>3. To set individual days rather than recurring schedule, click Add Day</li>
                <ul class="ml-2">
                  <li>▪ Select the date and times you are available. Click Save Schedule.</li>
                </ul>
                <li>4. If you require full days off from your recurring schedule, click Add Day under 'Holidays &amp; Days Off'. If you only require partial days off, you can still set hourly availability under 'Individual Daily Schedule'. These settings will override the recurring schedule.</li>
              </ol>
            </div>
          </AccordionTab>
          <AccordionTab header="Billing" v-if="hasProvinceOfPractice(loggedInUser, 'NS') && loggedInUser.provider_type == 'FFS'">
            Completing the MSI Billing Form allows Virtual Hallway to bill MSI on your behalf using billing codes 03.09L and 03.09K. You will receive an e-transfer for 100% of your billings the first week of every month for the consults you complete.<br /><br />
            Click <a target="_blank" style="text-decoration: underline" href="https://app.virtualhallway.ca/images/nova_scotia_billing_guide.pdf">here</a> for more information.
          </AccordionTab>
          <AccordionTab header="Billing" v-if="hasProvinceOfPractice(loggedInUser, 'ON') && loggedInUser.provider_type == 'FFS'">
            Completing the Authorization for Group Payment form allows Virtual Hallway to bill OHIP on your behalf using billing codes K730 and K731. You will receive an e-transfer for your billings the first week of every month for the consults you complete.<br /><br />
            Click <a target="_blank" style="text-decoration: underline" href="https://app.virtualhallway.ca/images/ontario_billing_guide.pdf">here</a> for more information.
          </AccordionTab>
          <AccordionTab header="Share Consults">
            <p class="tip-title mb-3 text-sm">You can share consults within the members of your group practice.</p>
            <div class="my-2">
              <p class="text-sm font-bold">To share consults:</p>
              <ol class="text-sm">
                <li>1. Click My Consults in the sidebar, then select the "Share Consults".</li>
                <li>2. Select the group practice you want to share with from the dropdown.</li>
                <li>3. Select the consults you want to share and click Share.</li>
                <li>4. Confirm your choice, once confirmed the members of your group practice will be able to view the shared consults.</li>
              </ol>
              <div class="flex flex-row justify-center mt-8">
                <TutorialVideoDialog video="practice_group_share_consults" buttonLabel="Watch Tutorial Video" />
              </div>
            </div>
          </AccordionTab>
          <AccordionTab header="Setting Schedule" v-if="isSpecialist(loggedInUser)">
            <p class="tip-title mb-3 text-sm">To receive consults, you need to have availability on the platform. Availability can be scheduled or on-demand.</p>
            <div class="mt-2">
              <p class="text-sm font-bold">To set your availability:</p>
              <ol class="text-sm">
                <li>1. Click My Availability from the left-hand sidebar.</li>
                <li>2. To add recurring availability, click the "+" button on the right side of a weekday. Select the time intervals and confirm your schedule for that weekday.</li>
                <li>3. To add an individual day availability, click Add Day button from the Individual Daily Schedule section. Select the time intervals and confirm your schedule. Individual day schedules will replace any recurring schedule for the day.</li>
                <li>4. If you to be unavailable for individual days, click Add Day from the Holidays and Day Off section. If you add a day off, it will replace your recurring and individual schedule for that day.</li>
              </ol>
              <div class="flex flex-row justify-center mt-8">
                <TutorialVideoDialog video="setting_availability_specialist" buttonLabel="Watch Tutorial Video" />
              </div>
            </div>
          </AccordionTab>
          <AccordionTab header="Consult Duration/Buffer Preferences" v-if="isSpecialist(loggedInUser)">
            <p class="tip-title mb-3 text-sm">As a {{ getReferralPersonTerm(actingUser) }}, you can decide on the length of your consults as well as how far in advance practitioners must book.</p>
            <div class="mt-2">
              <p class="text-sm font-bold">To adjust these preferences:</p>
              <ol class="text-sm">
                <li>1. Click My Availability from the left-hand sidebar.</li>
                <li>2. To adjust consult duration, click the arrows up or down below the Consult Duration heading.</li>
                <li>3. To adjust the amount of time in advance practitioners can book with you, click the up or down arrows below the Consult Buffer heading to your preferred amount of time.</li>
                <li>4. Click Confirm Changes.</li>
              </ol>
            </div>
          </AccordionTab>
          <AccordionTab header="OnDemand Availability">
            <p class="tip-title mb-3 text-sm">You can be available for consults on demand so family doctors can book anytime while you can give them a call at your convenience.</p>
            <div class="my-2">
              <p class="text-sm font-bold">To setup OnDemand:</p>
              <ol class="text-sm">
                <li>1. Click My Availability in the sidebar, and scroll down to OnDemand Consult Settings.</li>
                <li>2. Click Enable OnDemand Consults (if not already enabled).</li>
                <li>3. Set the maximum number of consults that can be booked within your turnaround time.</li>
                <li>4. Set the turnaround response time for your OnDemand consults.</li>
              </ol>
              <div class="flex flex-row justify-center mt-8">
                <TutorialVideoDialog video="on_demand_specialist" buttonLabel="Watch Tutorial Video" />
              </div>
            </div>
          </AccordionTab>
        </Accordion>
        <h4 class="text-md mb-2 mt-4 font-semibold text-gray-500">Your Account</h4>
        <Accordion v-model:activeIndex="openedSupportSectionAccountAccordionIndex">
          <AccordionTab header="Changing Account Info">
            <p class="tip-title mb-3 text-sm" @click="showFakeErrorLoggedIn()">You can change your E-Transfer email, phone number or name on Virtual Hallway at any time. However, in order to change your login email you must contact support@virtualhallway.ca</p>
            <div class="mt-2">
              <p class="text-sm font-bold">To Change E-Transfer or Phone Number</p>
              <ol class="text-sm">
                <li>1. Click Account in the sidebar.</li>
                <li>2. Under the Address/Billing Info header, you can type in a new e-transfer email. Click Confirm Changes to save.</li>
              </ol>
              <div class="flex flex-row justify-center mt-8">
                <TutorialVideoDialog v-if="isGP(this.loggedInUser)" video="my_account_pcp" buttonLabel="Watch Tutorial Video" />
                <TutorialVideoDialog v-if="isSpecialist(this.loggedInUser)" video="my_account_specialist" buttonLabel="Watch Tutorial Video" />
              </div>
            </div>
          </AccordionTab>
          <AccordionTab header="Upload Profile Picture">
            <p class="tip-title text-sm">Uploading a profile picture improves connection and the quality of interactions.</p>
            <div class="mt-2">
              <p class="text-sm font-bold">To Upload a Profile Picture:</p>
              <ol class="text-sm">
                <li>1. Click Account in the sidebar.</li>
                <li>2. Scroll down to the Profile Picture/Avatar header at the bottom of the page.</li>
                <li>3. Click Choose Profile Picture. Then select the Picture you would like to upload from your files. Alternatively, you can drag and drop your profile picture into the box. To confirm change, select 'Update Profile Picture'.</li>
                <li>4. If you prefer to use an avatar, click on your preferred image and then 'Select Avatar' to confirm.</li>
              </ol>
              <div class="flex flex-row justify-center mt-8">
                <TutorialVideoDialog v-if="isGP(this.loggedInUser)" video="my_account_pcp" buttonLabel="Watch Tutorial Video" />
                <TutorialVideoDialog v-if="isSpecialist(this.loggedInUser)" video="my_account_specialist" buttonLabel="Watch Tutorial Video" />
              </div>
            </div>
          </AccordionTab>
          <AccordionTab header="Enabling and Disabling Notifications">
            <p class="tip-title mb-3 text-sm">Virtual Hallway provides email and SMS text message notifications for a number of different actions pertaining to the consult process. These notifications help keep you informed about your consults. You are able to customize the notifications you receive.</p>
            <div class="mt-2">
              <p class="text-sm font-bold">To change Notification Settings:</p>
              <ol class="text-sm">
                <li>1. Click Account in the sidebar, then select the "Notifications" tab.</li>
                <li>2. Customize which notifications you want to receive.</li>
                <li>3. When you are satisfied with your notifications settings, scroll down and click Confirm changes.</li>
              </ol>
              <div class="flex flex-row justify-center mt-8">
                <TutorialVideoDialog v-if="isGP(this.loggedInUser)" video="my_account_pcp" buttonLabel="Watch Tutorial Video" />
                <TutorialVideoDialog v-if="isSpecialist(this.loggedInUser)" video="my_account_specialist" buttonLabel="Watch Tutorial Video" />
              </div>
            </div>
          </AccordionTab>
          <AccordionTab header="Two Factor Authentication">
            <p class="tip-title mb-3 text-sm">Two Factor Authentication helps make your account more secure and Virtual Hallway recommends you use if for your account. With Two Factor Authentication enabled, you will be asked to input a code each time you log in, which will be sent to your phone via SMS text message.</p>
            <div class="mt-2">
              <p class="text-sm font-bold">To enable Two Factor Authentication:</p>
              <ol class="text-sm">
                <li>1. Click Account in the sidebar, then select the "Security" tab.</li>
                <li>2. Under the Two Factor Authentication Heading you will see a toggle bar. Click this to turn on or off Two Factor Authentication.</li>
                <li>3. You will be prompted to confirm that you want to change your two factor authentication settings. If you want to proceed click Yes.</li>
                <li>4. To confirm the changes, a Text Message with a code will be sent to your phone. Enter the code and click Update Two Factor Authentication Settings.</li>
              </ol>
              <div class="flex flex-row justify-center mt-8">
                <TutorialVideoDialog v-if="isGP(this.loggedInUser)" video="my_account_pcp" buttonLabel="Watch Tutorial Video" />
                <TutorialVideoDialog v-if="isSpecialist(this.loggedInUser)" video="my_account_specialist" buttonLabel="Watch Tutorial Video" />
              </div>
            </div>
          </AccordionTab>
          <AccordionTab header="Changing your Password">
            <p class="tip-title mb-3 text-sm">We recommend that you maintain a password according to best practices. This includes a long password with uppercase and lowercase letters, numbers and symbols.</p>
            <div class="my-2">
              <p class="text-sm font-bold">To change your password:</p>
              <ol class="text-sm">
                <li>1. Click Account in the sidebar, then select the "Security" tab.</li>
                <li>2. Click Password Settings.</li>
                <li>3. Enter your current password and your new password twice.</li>
                <li>4. Click Confirm Change Password.</li>
              </ol>
              <div class="flex flex-row justify-center mt-8">
                <TutorialVideoDialog v-if="isGP(this.loggedInUser)" video="my_account_pcp" buttonLabel="Watch Tutorial Video" />
                <TutorialVideoDialog v-if="isSpecialist(this.loggedInUser)" video="my_account_specialist" buttonLabel="Watch Tutorial Video" />
              </div>
            </div>
          </AccordionTab>
          <AccordionTab header="Autofax Feature">
            <p class="tip-title mb-3 text-sm">Your consult reports can be autofaxed to your preferred fax number.</p>
            <div class="my-2">
              <p class="text-sm font-bold">To setup autofax:</p>
              <ol class="text-sm">
                <li>1. Click Account in the sidebar, then select the "Fax Numbers" tab.</li>
                <li>2. Enter a Contact Name and Fax Number.</li>
                <li>3. Click Add a Verified Contact.</li>
                <li>4. Your consult reports will be faxed to the added fax number. To turn off autofax for the number, you can deselect the Auto Send option from the Saved Fax Contacts list.</li>
              </ol>
              <div class="flex flex-row justify-center mt-8">
                <TutorialVideoDialog video="using_autofax" buttonLabel="Watch Tutorial Video" />
              </div>
            </div>
          </AccordionTab>
          <AccordionTab header="Claiming CME Credits">
            <div class="my-2">
              <p class="text-sm font-bold">To claim credits for a CME:</p>
              <ol class="text-sm">
                <li>1. Click CME Lectures in the sidebar.</li>
                <li>2. Select the lecture you want to claim credit for and click Claim CME Credit button.</li>
                <li>3. Fill-out the form and submit it.</li>
                <li>4. An email with your credits will be sent to you right away.</li>
              </ol>
              <div class="flex flex-row justify-center mt-8">
                <TutorialVideoDialog video="cme_lectures_credits" buttonLabel="Watch Tutorial Video" />
              </div>
            </div>
          </AccordionTab>
        </Accordion>
        <div class="mt-6 w-full">
          <Button id="contactSupportTabButton" @click="activePanelIndex = 1" text class="w-full" label="Get Help" />
        </div>
      </TabPanel>
      <TabPanel header="Contact Support" id="contactSupportTab">
        <div class="align-start relative mt-3 flex w-full flex-col items-start justify-start rounded-lg bg-secondary remove-later p-4 text-left">
          <h1 class="mb-1 text-lg font-bold text-white">Need Help?</h1>
          <h1 class="text-xs text-gray-200">Submit a support request and we'll be in touch as soon as possible.</h1>
        </div>

        <div class="mt-3 rounded-lg border-2 border-solid border-gray-300 p-4">
          <ContactSupportForm />
        </div>

        <div class="mt-3 rounded-lg border-2 border-solid border-gray-300 p-4">
          <SupportCallMe @toggleHelp="toggleHelp" />
        </div>
      </TabPanel>

      <TabPanel header="Tutorials" v-if="!isNativePlatform() && !isPreboarding(loggedInUser)">
        <div class="flex w-full p-2">
          <Button label="Book a Consult" icon="pi pi-id-card" class="p-button-outlined w-full" @click="goToTutorial('book_a_consult')" />
        </div>
        <div v-if="isSpecialist(this.loggedInUser) || isOrgUnitHead(this.loggedInUser)" class="flex w-full p-2">
          <Button label="Set Availability" icon="pi pi-calendar-times" class="p-button-outlined w-full" @click="goToTutorial('set_availability')" />
        </div>
        <div v-if="isOrgUnitHead(this.loggedInUser)" class="flex w-full p-2">
          <Button label="Add User to Practice Group" icon="pi pi-users" class="p-button-outlined w-full" @click="goToTutorial('add_user_to_org_unit')" />
        </div>
      </TabPanel>
    </TabView>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import ContactSupportForm from './ContactSupportForm.vue';
import SupportCallMe from './SupportCallMe.vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Button from 'primevue/button';
import TutorialVideoDialog from '../misc/TutorialVideoDialog.vue';
import { mapGetters } from 'vuex';

export default {
  inject: ['mixpanel'],
  emits: ['toggleHelp'],
  components: {
    Accordion,
    AccordionTab,
    ContactSupportForm,
    SupportCallMe,
    TabView,
    TabPanel,
    Button,
  },
  data() {
    return {
      activePanelIndex: 0,
      accountAccordionIndex: null,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'isSpecialist', 'isGP', 'isOrgUnitHead', 'hasProvinceOfPractice', 'isPreboarding', 'openedSupportSectionKnowledgebaseAccordionIndex', 'openedSupportSectionAccountAccordionIndex', 'actingUser']),
  },
  mounted() {
    let dom = document.getElementsByClassName(`p-accordion-header p-highlight`)[0];
    if (dom) {
      dom.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  },
  methods: {
    ...mapMutations(['setTutorialStatus']),
    goToTutorial(tutorialName) {
      if (tutorialName == 'book_a_consult') {
        this.setTutorialStatus('select-a-specialty');
        this.mixpanel.track('Help Center Tutorial - Book A Consult');
        this.$router.push('/makereferral');
      } else if (tutorialName == 'set_availability') {
        this.mixpanel.track('Help Center Tutorial - Set Availability');
        this.setTutorialStatus('set-availability');
        if (this.isOrgUnitHead(this.loggedInUser)) {
          this.setTutorialStatus('org-select-user');
        }
        this.$router.push('/schedules');
      } else if (tutorialName == 'add_user_to_org_unit') {
        this.setTutorialStatus('click-add-user');
        this.$router.push('/manageusers');
      }
      this.$emit('toggleHelp');
    },
    toggleHelp() {
      this.$emit('toggleHelp');
    },
    showFakeErrorLoggedIn() {
      //for testing
      this.showGenericErrorToast();
    },
  },
  watch: {
    activePanelIndex(val, oldVal) {
      if (val == 0) {
        this.mixpanel.track('Help Center - Clicked Contact Support');
      } else if (val == 1) {
        this.mixpanel.track('Help Center - Clicked Knowledgebase');
      } else if (val == 2) {
        this.mixpanel.track('Help Center - Clicked Tutorials');
      }
    },
    openedSupportSectionKnowledgebaseAccordionIndex(val, oldVal) {
      if (val == 0) {
        this.mixpanel.track('Knowledgebase - Booking a Consult');
      } else if (val == 1) {
        this.mixpanel.track('Knowledgebase - Consult Statuses');
      } else if (val == 2) {
        this.mixpanel.track('Knowledgebase - Finding Your Consults');
      } else if (val == 3) {
        this.mixpanel.track('Knowledgebase - Cancelling a Booked Consult');
      } else if (val == 4) {
        this.mixpanel.track('Knowledgebase - Rescheduling an Appointment');
      } else if (val == 5) {
        this.mixpanel.track('Knowledgebase - Scheduling Availability');
      } else if (val == 6) {
        this.mixpanel.track('Knowledgebase - Consult Duration/Buffer Preferences');
      }
    },
    openedSupportSectionAccountAccordionIndex(val, oldVal) {
      if (val == 0) {
        this.mixpanel.track('Knowledgebase - Changing Account Info');
      } else if (val == 1) {
        this.mixpanel.track('Knowledgebase - Upload Profile Picture');
      } else if (val == 2) {
        this.mixpanel.track('Knowledgebase - Enabling and Disabling Notifications');
      } else if (val == 3) {
        this.mixpanel.track('Knowledgebase - Two Factor Authentication');
      } else if (val == 4) {
        this.mixpanel.track('Knowledgebase - Changing Your Password');
      }
    },
  },
};
</script>

<style>
.p-accordion .p-accordion-header .p-accordion-header-link {
  background: white !important;
}
.p-accordion .p-accordion-header .p-accordion-header-link:hover {
  background: #eef0f1 !important;
}
.p-accordion-header-text {
  font-family: 'Poppins';
  font-weight: 500;
}
.tip-title {
  color: #385b94;
  background: #edf0ff;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  border: 1px solid #cad6f4;
}

li {
  margin-bottom: 5px;
}

.flex-col .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff #dee2e6;
  color: #ef5164;
}

.p-sidebar-header {
  z-index: 1000 !important;
}
</style>
