<template>
  <div class="main-inner-wrap" v-if="!isLoading">
    <div class="align-start relative mt-4 mb-1 flex w-full flex-row items-start justify-start rounded-2xl bg-secondary remove-later p-6 text-left shadow">
      <div class="hidden lg:block"><i class="pi pi-users mb-2 mt-2 mr-5 text-white" style="font-size: 2.5rem"></i></div>
      <div class="flex flex-col">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">Manage Users</h1>
        <h1 v-if="isOrgUnitHead(loggedInUser)" class="text-sm text-gray-200">You can manage the users in your department using this portal. To add a new user, you can set up their account via the button below. To add existing users to your practice group, please contact support.</h1>
        <h1 v-else-if="isBookingHallwayAdmin(loggedInUser)" class="text-sm text-gray-200">You can manage the users in your department using this portal. The list below shows the statuses of the users in your department. To add a new user, press the Add User button and enter their email address. You can remove users from your department, however this does not delete their account and the user can still access Virtual Hallway.</h1>
        <!-- <Button v-if="tutorialFit()" @click="startTutorial" v-on:click.stop label="Add Users Tutorial" icon="pi pi-external-link" class="p-button-outlined p-button-secondary p-button-sm hide-mobile z-10 text-xs" id="tips-button" iconPos="right" /> -->
      </div>
    </div>
    <div class="relative w-full pt-4">
      <OrgUnitUsersList :users="users" @userRemoved="userRemoved" />
      <template v-if="tutorialStatus === 'click-add-user'">
        <div v-if="tutorialFit()" class="org-add-user-tooltip py-4 px-4">
          <h1 class="text-left text-xl font-bold text-white">First, we need you to add the doctors and nurse practitioners from your clinic to your account.</h1>
          <br />
          <div class="text-left font-bold text-white">
            <p>Click Add User.</p>
            <br />
          </div>
          <div class="flex flex-row justify-between">
            <button class="text-xs font-bold text-white" @click="skipTutorial" id="skipTutorialBtn">Skip Tutorial</button>
            <div class="text-xs font-bold text-white">1/3</div>
          </div>
        </div>
      </template>
      <template v-if="tutorialStatus === 'add-user-complete-gp' || tutorialStatus === 'add-user-complete-sp'">
        <div v-if="tutorialFit()" class="org-add-complete-tooltip py-4 px-4">
          <h1 class="text-left text-lg font-bold text-white">Awesome! You have added the first practitioner to the Practice Group Account. You can add more physicians and nurse practitioners in the same way.</h1>
          <br />
          <template v-if="tutorialStatus === 'add-user-complete-gp'">
            <div class="text-left font-bold text-white">
              Let’s get started making your first consult.
              <br />
            </div>
            <br />
            <div class="text-left font-bold text-white">
              <p>Click Book a Consult.</p>
              <br />
            </div>
          </template>
          <template v-if="tutorialStatus === 'add-user-complete-sp'">
            <div class="text-left font-bold text-white">
              You’ll notice that their status is listed as “Onboarding”. This means that a photo ID needs to be uploaded under the Verification tab.
              <br />
            </div>
            <br />
            <div class="text-left font-bold text-white">
              <p>Click Verification.</p>
              <br />
            </div>
          </template>
          <div class="flex flex-row justify-between">
            <button class="text-xs font-bold text-white" @click="skipTutorial" id="skipTutorialBtn">Skip Tutorial</button>
            <div class="text-xs font-bold text-white">3/3</div>
          </div>
        </div>
      </template>
    </div>
  </div>
  <modal v-if="tutorialFit()" ref="tutorialModal" class="">
    <template v-slot:header>
      <div class="flex w-full justify-center">
        <h1 class="text-xl font-bold mt-8 mb-2">Hi {{ loggedInUser.first_name }} {{ loggedInUser.last_name }}, Welcome to Virtual Hallway.</h1>
      </div>
    </template>

    <template v-slot:body>
      <div class="max-w-lg px-6 py-2">
        <div class="text-centre text-sm">We are thrilled to have you join this local community of health care providers. Let’s get you set up so that everyone at your clinic can quickly start doing phone consults.</div>
        <br />
        <div class="mb-2">
          <Button class="get-started-button dark-mint-button-background" @click="startTutorial" id="tutorialGetStartedBtn"> Get Started </Button>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="text-s rounded-b mb-6">
        <button class="pt-4 font-bold" @click="skipTutorial" id="notReadyToConsultBtn">Skip Tutorial?</button>
      </div>
    </template>
  </modal>
  <TimezoneModal :openModal="openTimezoneModal" />
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import OrgUnitUsersList from '@/components/org_unit/ViewUsers.vue';
import Loading from 'vue-loading-overlay';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Modal from '@/components/misc/Modal.vue';
import Button from 'primevue/button';
import TimezoneModal from '@/components/misc/TimezoneModal.vue';
import moment from 'moment';

export default {
  components: {
    OrgUnitUsersList,
    Loading,
    Modal,
    Button,
    TimezoneModal,
  },
  data() {
    return {
      isLoading: false,
      openTimezoneModal: false,
      users: [],
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'orgUnitUsers', 'tutorialStatus', 'getUserActionByName', 'isOrgUnitHead']),
  },
  methods: {
    ...mapActions(['getOrgUnitUsers', 'getUsersByBookingHallwayId']),
    ...mapMutations(['setTutorialStatus', 'updateLoggedInUser']),
    skipTutorial() {
      this.updateUserTutorialByName(this.loggedInUser, 'add_user_to_org_unit', true, 'Skipped');
      this.setTutorialStatus('');
      this.$refs.tutorialModal.closeModal();
    },
    startTutorial() {
      this.setTutorialStatus('click-add-user');
      this.loggedInUser.first_login = false;
      this.updateLoggedInUser(this.loggedInUser);
      this.$refs.tutorialModal.closeModal();
    },
    userRemoved(userId) {
      this.users = this.users.filter((u) => u.id != userId);
    },
  },
  created() {
    this.isLoading = true;
    if (!this.isBookingHallwayAdmin(this.loggedInUser)) {
      this.getOrgUnitUsers(this.loggedInUser.org_units[0].id)
        .then(() => {
          this.users = this.orgUnitUsers;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    } else {
      this.getUsersByBookingHallwayId(this.loggedInUser.booking_hallways[0].id)
        .then((data) => {
          this.users = data;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    }
  },
  mounted() {
    let userTutorial = this.getUserActionByName(this.loggedInUser, 'add_user_to_org_unit');
    const now = moment();
    const nowDefaultTimezone = now.toDate().toLocaleString('en-US', { timeZone: this.loggedInUser.default_timezone });

    if (this.getCurrentTimezoneLocalString() !== nowDefaultTimezone && !this.loggedInUser.hasViewDefaultTimezoneModal) {
      this.updateLoggedInUser({ ...this.loggedInUser, hasViewDefaultTimezoneModal: true });
      this.openTimezoneModal = true;
    } else if (userTutorial && userTutorial.status == 'Active' && this.tutorialFit()) {
      this.$refs.tutorialModal.openModal();
    }
  },
};
</script>

<style>
.org-add-complete-tooltip {
  width: 300px;
  background-color: #fff;
  color: black;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1000;
  top: -150px;
  left: 55px;
  margin-left: -60px;
  opacity: 1;
  transition: opacity 0.3s;
  background-color: var(--vh-new-feature-blue);
}

.org-add-complete-tooltip::after {
  transform: rotate(90deg);
  content: '';
  position: absolute;
  top: 50%;
  left: -2%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: var(--vh-new-feature-blue) transparent transparent transparent;
}

.org-add-user-tooltip {
  width: 200px;
  background-color: #fff;
  color: black;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 100;
  top: 10px;
  left: -130px;
  margin-left: -70px;
  opacity: 1;
  transition: opacity 0.3s;
  background-color: var(--vh-new-feature-blue);
}
.org-add-user-tooltip::after {
  transform: rotate(270deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 102%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: var(--vh-new-feature-blue) transparent transparent transparent;
}
</style>
