<template>
  <form method="post" @submit.prevent="submit" class="grid grid-cols-2 gap-y-7 gap-x-4">
    <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
      <span class="p-float-label">
        <InputText type="text" id="firstName" name="firstName" v-model="user.first_name" required class="w-full" maxLength="55" />
        <label for="firstName">First Name </label>
      </span>
    </div>
    <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
      <span class="p-float-label">
        <InputText type="text" id="lastName" name="lastName" v-model="user.last_name" required class="w-full" maxLength="55" />
        <label for="lastName">Last Name </label>
      </span>
    </div>

    <div class="col-span-2 mb-2">
      <span class="p-float-label">
        <InputText type="email" name="email" id="email" v-model="user.email" required :class="['w-full']" maxLength="255" :disabled="isEmailDisabled" />
        <label for="email">Email </label>
      </span>
    </div>

    <div class="col-span-2 mb-2" v-if="user.background_role != 'cme'">
      <span v-tooltip.left="'This is the default number that specialists will call to reach you.'" class="p-float-label">
        <InputMask type="tel" mask="(999)-999-9999" id="phoneNumber" name="phoneNumber" v-model="phoneNumberWithMask" :required="true" :autoClear="false" :class="['w-full', { 'p-invalid': phoneNumberError }]" @change="validatePhoneNumberRegistrationForm" maxlength="16" />
        <label for="phoneNumber"
          >Cell Number <span class="error" v-if="phoneNumberErrorMsg !== ''">{{ phoneNumberErrorMsg }}</span>
        </label>
      </span>
    </div>
    <!-- Keeping these commented instead of removed for now -->
    <!-- <div class="col-start-1 col-end-3 mb-2 sm:col-auto" v-if="user.background_role == 'cme'">
      <span class="p-float-label text-left">
        <Dropdown :class="['w-full', { 'p-invalid': provinceError }]" v-model="user.province" :options="allProvinces" id="province" placeholder="Province" required />
        <label for="province">Province </label>
      </span>
    </div> -->
    <div class="col-start-1 col-end-3 mb-2 sm:col-auto" v-if="user.background_role == 'cme'">
      <span class="p-float-label">
        <InputText v-model="user.postal_code" :class="['w-full', { 'p-invalid': postalCodeError }]" name="postalCode" id="postalCode" @change="validatePostalCode" maxLength="6" required />
        <label for="postalCode">Postal Code</label>
      </span>
      <span class="error" v-if="postalCodeError"> Please Enter Postal Code with Format A1A1A1 </span>
    </div>
    <div class="col-start-1 col-end-3 mb-2 sm:col-auto" v-if="user.background_role == 'cme'">
      <span class="p-float-label text-left">
        <Dropdown :class="['w-full', { 'p-invalid': professionError }]" v-model="user.profession" :options="allProfessions" id="profession" placeholder="Profession" name="profession" required />
        <label for="profession">Profession</label>
      </span>
    </div>
    <div class="col-span-2 mb-2" v-if="user.profession === 'Other Healthcare Profession' || user.profession === 'Non Healthcare Profession'">
      <div class="p-float-label text-left">
        <InputText type="text" name="other_profession" id="other_profession" v-model="user.other_profession" class="w-full" maxLength="55" required />
        <label for="other_profession">Enter Your Profession</label>
      </div>
    </div>

    <div class="col-span-2 mb-2" v-if="!loggedInUser">
      <span class="p-float-label">
        <Password name="password" :inputId="'password'" v-model="user.password" :toggleMask="true" required :class="['w-full', { 'p-invalid': passwordError }]" inputStyle="background-color:#fafafa!important;" maxLength="255">
          <template #footer>
            <p class="my-2 text-xs font-bold">Requirements</p>
            <ul class="p-pl-1 p-ml-1 registration-form-password-strength text-xs" style="line-height: 1.5">
              <li><i :class="['pi', !/[a-z]/.test(user.password) ? 'pi-times-circle' : 'pi-check-circle']" style="fontsize: 0.8rem"></i> At least one lowercase</li>
              <li><i :class="['pi', !/[A-Z]/.test(user.password) ? 'pi-times-circle' : 'pi-check-circle']" style="fontsize: 0.8rem"></i> At least one uppercase</li>
              <li><i :class="['pi', !/[0-9]/.test(user.password) ? 'pi-times-circle' : 'pi-check-circle']" style="fontsize: 0.8rem"></i> At least one number</li>
              <li><i :class="['pi', !passwordRegex.test(user.password) ? 'pi-times-circle' : 'pi-check-circle']" style="fontsize: 0.8rem"></i> At least one special character</li>
              <li><i :class="['pi', user.password.length < 10 ? 'pi-times-circle' : 'pi-check-circle']" style="fontsize: 0.8rem"></i> Minimum 10 characters</li>
            </ul>
          </template>
        </Password>
        <label for="password">Set Your Password </label>
      </span>
    </div>

    <div class="col-start-1 col-end-3 -mt-4" v-if="!loggedInUser && passwordError">
      <span class="registration-error"> Passwords Do Not Meet the Following Requirements:{{ passwordErrorMessage }} </span>
    </div>

    <div class="col-span-2 -mt-1">
      <Button v-if="background_role == 'cme'" type="submit" id="submit" class="p-button-secondary w-full text-center" label="Register"></Button>
      <Button v-else type="submit" id="submit" class="p-button-secondary w-full text-center" label="Join"></Button>
    </div>
    <div class="col-span-2 -mt-4 flex justify-center pl-2">
      <label class="pl-1 text-xs"> &nbsp;By registering, I agree to the Virtual Hallway <EulaDialog /></label>
    </div>
  </form>

  <ConfirmDialog :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '50vw' }" style="max-width: 470px" :draggable="false" group="templating">
    <template #message="slotProps">
      <div class="flex flex-col justify-center items-center w-full py-3">
        <div class="flex justify-center items-center rounded-full border-4 border-green-400 w-24 h-24 mb-4">
          <i :class="slotProps.message.icon" style="font-size: 2.5rem" class="text-green-400"></i>
        </div>
        <p class="pl-2">{{ slotProps.message.message }}</p>
      </div>
    </template>
  </ConfirmDialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import Dropdown from 'primevue/dropdown';
import Password from 'primevue/password';
import Button from 'primevue/button';
import EulaDialog from '@/components/misc/EulaDialog.vue';

export default {
  components: {
    InputText,
    InputMask,
    Dropdown,
    Password,
    Button,
    EulaDialog,
  },
  props: ['loggedInUser', 'background_role', 'lecture_id'],
  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        is_in_email_list: true,
        password: '',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        background_role: '',
        province: '',
        postal_code: '',
        profession: '',
        other_profession: '',
        invite_code: null,
        affiliate_id: '',
      },
      phoneNumberWithMask: '',
      error: '',
      phoneNumberError: false,
      phoneNumberErrorMsg: '',
      phoneCountryCodes: [{ name: '+1 (CAN/USA)', value: '1' }],
      selectedPhoneCountryCode: null,
      firstPhoneNumberChange: false,
      passwordError: false,
      postalCodeRegex: /([A-Za-z][0-9]){3}/,
      postalCodeError: false,
      provinceError: false,
      professionError: false,
      eulaAgreed: true,
      passwordRegex: /[\s~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?()._]/,
      isEmailDisabled: false,
      allProfessions: ['Physician', 'Nurse Practitioner', 'Other Healthcare Profession', 'Non Healthcare Profession'],
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'isGP', 'isOrgUnitHead', 'isLoggedIn']),
  },
  methods: {
    ...mapActions(['register', 'login', 'registerUserForZoomLecture']),
    submit() {
      if (this.validate()) {
        this.user.phone_country_code = this.selectedPhoneCountryCode.value;
        this.user.phone_number = this.phoneNumberWithMask.replace(/[^0-9]/g, '');
        if (this.user.background_role == 'cme' && this.user.other_profession != '') {
          this.user.profession = this.user.other_profession;
        }
        this.handleRegistration(this.user);
      }
    },
    validate() {
      let valid = true;
      if (this.user.background_role == 'cme') {
        this.validateProfession();
        if (this.professionError) {
          valid = false;
        }
        // this.validateProvince();
        // if (this.provinceError) {
        //   valid = false;
        // }
        this.validatePostalCode();
        if (this.postalCodeError) {
          valid = false;
        }
      }
      this.validatePassword();
      if (this.passwordError) {
        valid = false;
      }
      this.validatePhoneNumberRegistrationForm();
      if (this.phoneNumberError) {
        valid = false;
      }
      return valid;
    },
    validatePassword() {
      this.passwordError = false;
      this.passwordErrorMessage = '';
      if (this.user.password.length < 10) {
        this.passwordErrorMessage += ' Minimum 10 characters';
        this.passwordError = true;
      }
      if (!/[a-z]/.test(this.user.password)) {
        this.passwordErrorMessage += ' One Lower Case Character';
        this.passwordError = true;
      }
      if (!/[A-Z]/.test(this.user.password)) {
        this.passwordErrorMessage += ' One Upper Case Character';
        this.passwordError = true;
      }
      if (!/[0-9]/.test(this.user.password)) {
        this.passwordErrorMessage += ' One Number';
        this.passwordError = true;
      }
      if (!this.passwordRegex.test(this.user.password)) {
        this.passwordErrorMessage += ' One Special Character (!,@,#,$,etc.)';
        this.passwordError = true;
      }
    },
    validateProfession() {
      if (this.user.profession == '') {
        this.professionError = true;
      } else {
        this.professionError = false;
      }
    },
    // validateProvince() {
    //   if (this.user.province == '') {
    //     this.provinceError = true;
    //   } else {
    //     this.provinceError = false;
    //   }
    // },
    validatePostalCode() {
      if (this.user.postal_code === null) {
        this.postalCodeError = true;
      } else if (this.user.province == 'United States') {
        this.postalCodeError = false;
      } else {
        this.user.postal_code = this.user.postal_code.replace(/\s+/g, '');
        if (this.user.postal_code.length === 5 || (this.user.postal_code.length === 6 && this.postalCodeRegex.test(this.user.postal_code))) {
          this.postalCodeError = false;
        } else {
          this.postalCodeError = true;
        }
      }
    },
    validatePhoneNumberRegistrationForm() {
      if (this.firstPhoneNumberChange) {
        this.phoneNumberError = false;
        this.phoneNumberErrorMsg = '';
        this.user.phone_number = this.phoneNumberWithMask.replace(/[^0-9]/g, '');
        if (!/[0-9]/.test(this.user.phone_number) || this.user.phone_number.length != 10) {
          this.phoneNumberError = true;
          this.phoneNumberErrorMsg = 'Must be 10 digits. ';
        }
      } else {
        this.firstPhoneNumberChange = true;
      }
    },
    handleRegistration() {
      this.isLoading = true;
      this.user.isMobile = this.isNativePlatform();
      this.register(this.user)
        .then((res) => {
          if (this.lecture_id) {
            this.handleZoomLectureRegistration();
          }
          if (this.user.invite_code) {
            this.login({ email: this.user.email, password: this.user.password, is_mobile: this.isNativePlatform() })
              .then((res) => {
                if (res.data.user.email_verified_at) {
                  this.isLoading = false;
                  this.handleSuccessfulLogin();
                } else {
                  this.isLoading = false;
                  this.$router.push({ path: '/home' });
                }
              })
              .catch(() => {
                this.isLoading = false;
                this.showGenericErrorToast();
              });
          } else if (!this.lecture_id) {
            this.isLoading = false;
            this.showVerifyEmailDialog('Please check your email for a link to verify your account.');
          }
        })
        .catch((res) => {
          this.isLoading = false;
          if (res.status === 422) {
            if (res.data.errors.email && res.data.errors.email.includes('The email has already been taken.')) {
              this.login({ email: this.user.email, password: this.user.password, is_mobile: this.isNativePlatform() })
                .then((res) => {
                  if (res.data.user.email_verified_at) {
                    this.isLoading = false;
                    this.handleSuccessfulLogin();
                    if (this.lecture_id != '') {
                      this.handleZoomLectureRegistration();
                    }
                  }
                })
                .catch(() => {
                  this.isLoading = false;
                  this.$toast.add({
                    severity: 'error',
                    summary: 'Email already exists',
                    detail: 'That email already exists in our system. Please try to login.',
                    life: 10000,
                  });
                });
            } else if (res.data.errors.password && res.data.errors.password.includes('The given password has appeared in a data leak. Please choose a different password.')) {
              this.$toast.add({
                severity: 'error',
                summary: 'Please Choose Different Password',
                detail: 'It looks like that password is a weak password.',
                life: 10000,
              });
            } else {
              this.showGenericErrorToast();
            }
          } else {
            this.showGenericErrorToast();
          }
        });
    },
    handleZoomLectureRegistration() {
      this.registerUserForZoomLecture({
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email,
        zip: this.user.postal_code,
        province: this.user.province,
        profession: this.user.profession,
        affiliate_id: this.user.affiliate_id,
        lecture_id: this.lecture_id,
      })
        .then((response) => {
          //For existing users who are registering for a lecture through the registration form while logged out
          if (this.isLoggedIn) {
            this.$toast.add({
              severity: 'success',
              summary: 'Lecture Registration Successful',
              detail: 'Please check your email for instructions on how to join the lecture.',
              life: 10000,
            });
          } else {
            this.showVerifyEmailDialog('Check your email for a link to verify your Virtual Hallway account, as well as instructions on how to watch the lecture.');
          }
        })
        .catch(() => {
          //TODO: handle when the backend returns a faulty value for the lecture
          this.showGenericErrorToast();
        });
    },
    handleSuccessfulLogin() {
      if (this.$route.query.lec) {
        this.$router.push({ path: '/lecture/' + this.$route.query.lec, query: { lectureRegistrationSuccess: true } });
      } else if (this.isGP(this.loggedInUser)) {
        this.$router.push({ path: '/makereferral' });
      } else if (this.isOrgUnitHead(this.loggedInUser)) {
        this.$router.push({ path: '/manageusers' });
      } else {
        this.$router.push({ path: '/home' });
      }
    },
    showVerifyEmailDialog(message) {
      this.$confirm.require({
        group: 'templating',
        message: message,
        header: 'Verify Email',
        icon: 'pi pi-envelope',
        acceptLabel: 'Done',
        acceptClass: 'p-button-secondary',
        rejectLabel: 'Close',
        rejectClass: 'p-button-secondary p-button-text hidden',
        accept: () => {
          this.$router.push({ path: '/login' });
        },
      });
    },
  },

  created() {
    if (this.background_role == 'cme' || this.$route.query.br) {
      this.user.background_role = 'cme';
    }
    if (this.$route.query?.ref) {
      this.user.affiliate_id = this.$route.query?.ref;
    }
    if (this.$route.query.invited_email) {
      this.user.email = this.$route.query?.invited_email;
      this.user.first_name = this.$route.query?.invited_first_name;
      this.user.last_name = this.$route.query?.invited_last_name;
      this.phoneNumberWithMask = this.$route.query?.invited_phone_number;
      this.selectedPhoneCountryCode = { name: '+1 (CAN/USA)', value: '1' };
      this.user.demoAccountInvite = this.$route.query?.demo_account_invite ? JSON.parse(this.$route.query?.demo_account_invite) : false;
    }
    if (this.$route.query.demo_account_invite) {
      this.user.demoAccountInvite = this.$route.query.demo_account_invite ? JSON.parse(this.$route.query.demo_account_invite) : false;
    }
    if (this.$route.query.accept_invite) {
      this.user.invite_code = this.$route.query.c;
      this.user.email = this.$route.query.email;
      this.isEmailDisabled = true;
    }
    this.selectedPhoneCountryCode = this.phoneCountryCodes[0];
  },
};
</script>

<style scoped>
.registration-error {
  color: #dc3545;
  font-size: 0.8rem;
  text-align: text-left;
}

.registration-form-password-strength i.pi-times-circle {
  color: var(--vh-error-red);
  padding-right: 2px;
}

.registration-form-password-strength i.pi-check-circle {
  color: var(--vh-success-green);
  padding-right: 2px;
}

.p-float-label label {
  margin-left: 0.5rem;
}

.p-inputtext,
.p-dropdown,
.p-multiselect {
  background-color: #fafafa !important;
}
.p-input-icon-right > i {
  color: rgb(144, 144, 144) !important;
}
</style>
