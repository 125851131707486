<template>
  <div class="main-inner-wrap" v-if="!isLoading">
    <div class="align-start relative mt-4 mb-1 flex w-full flex-row items-start justify-start rounded-2xl bg-secondary remove-later p-6 text-left shadow">
      <div class="hidden lg:block">
        <i class="pi pi-list mb-2 mt-2 mr-5 text-white" style="font-size: 2.5rem"></i>
      </div>
      <div class="flex flex-col">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">SmartPath Results</h1>
        <h1 class="text-sm text-gray-200">The table below shows who has used your Pathway.</h1>
      </div>
    </div>
    <div v-if="smartPathTransactions.length < 1">
      <div class="seperator-dark-blue w-28"></div>
      <p>
        <span v-if="isSpecialist(actingUser)"> No one has viewed your Pathway yet! </span>
      </p>
    </div>
    <div class="w-full pt-4" v-else>
      <div v-for="transaction in smartPathTransactions" :key="transaction.id" class="flex flex-row w-full border-solid border-1 border-slate-600 hover:bg-slate-100 hover:cursor-pointer bg-white" @click="viewTransaction(transaction.id)">
        <div v-if="transaction.contributor_has_seen == 0" class="flex flex-col py-2">
          <div class="flex h-full ml-2 rounded-full" style="width: 3px; background-color: #ff6a78" />
        </div>
        <div class="flex flex-col w-full">
          <div class="w-full pt-5 pl-5">
            <div class="flex flex-row justify-left w-full">
              <div class="flex w-full justify-start text-xl text-red-600">{{ transaction.smart_path.name }} for {{ transaction.patient_first_name }} {{ transaction.patient_last_name }}</div>
              <div class="w-full flex flex-row justify-end pr-5">
                <div class="flex pr-2">
                  <Button @click="viewTransaction(transaction.id)" v-on:click.stop icon="pi pi-eye" label="View" :id="'specialist-view-transaction-button-' + transaction.id" class="p-button-sm" />
                </div>
                <Button @click="faxSmartPathResultOnClick(transaction.id)" v-on:click.stop icon="pi pi-file-pdf" label="Send As Fax" class="p-button-sm !mr-2" />
                <Button @click="downloadSmartPathResult(transaction.id)" v-on:click.stop icon="pi pi-download" label="Download" class="p-button-sm !mr-2" />
                <ToggleButton @change="toggleRead(transaction.id)" v-on:click.stop v-model="transaction.contributor_has_seen" onLabel="Mark as Not Reviewed" offLabel="Mark as Reviewed" onIcon="pi pi-envelope" offIcon="pi pi-envelope" />
              </div>
            </div>
          </div>
          <!-- 
          <div class="justify-left w-full pt-3 pb-5 pl-7 text-gray-500">
            <div class="flex flex-row text-md">
              Sent to: <span class="text-black ml-1">{{ transaction.smart_path_person.full_name }}</span>
            </div>
          </div> -->

          <div class="flex flex-row">
            <div class="flex flex-col w-full pt-3 pb-5 pl-7 text-gray-500">
              <!-- <div class="flex flex-row justify-start text-md">
                Completed By: <span class="text-black ml-1"> {{ transaction.referee.full_name }}</span>
              </div> -->
              <div class="flex flex-row justify-start text-sm">Created: {{ formatDateTime(transaction.created_at) }}</div>
              <div class="flex flex-row justify-start text-sm">Last Updated: {{ formatDateTime(transaction.created_at) }}</div>
            </div>

            <div v-if="JSON.parse(transaction.smart_path_result).length > 0" class="flex flex-col w-full pt-3 pb-5 pr-7 text-gray-500">
              <div class="flex flex-row justify-end text-md">
                <div v-if="JSON.parse(transaction.smart_path.smart_path)[JSON.parse(transaction.smart_path_result)[JSON.parse(transaction.smart_path_result).length - 1]['id']].node_type == 'terminal'">
                  Status:
                  <span class="text-black ml-1">Reached Terminal Node (can still edit)</span>
                </div>
                <div v-else>
                  Status:
                  <span class="text-black ml-1"> {{ transaction.status }}</span>
                </div>
              </div>
              <div v-if="JSON.parse(transaction.smart_path.smart_path)[JSON.parse(transaction.smart_path_result)[JSON.parse(transaction.smart_path_result).length - 1]['id']].triage_label != ''">
                <div class="flex flex-row justify-end text-md">
                  Triage Label:<span class="text-black ml-1">{{ JSON.parse(transaction.smart_path.smart_path)[JSON.parse(transaction.smart_path_result)[JSON.parse(transaction.smart_path_result).length - 1]['id']].triage_label }}</span>
                </div>
              </div>
            </div>
            <div v-else class="flex flex-col w-full pt-3 pb-5 pr-7 text-gray-500">
              <div class="flex flex-row justify-end text-md">Pathway not started.</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <SmartPathTransactionsList :transactions="smartPathTransactions" /> -->
    </div>
  </div>
  <FaxConsultDialog :show="showFaxConsultDialog" @closeFaxConsultDialog="showFaxConsultDialog = false" :fax_document="'smart_path_result'" :documentId="selectedTransactionId" :displayEditFaxOption="true" />
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="false" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SmartPathTransactionsList from '@/components/specialist/smartpath/SmartPathTransactionsList.vue';
import Loading from 'vue-loading-overlay';
import ToggleButton from 'primevue/togglebutton';
import Button from 'primevue/button';
import FaxConsultDialog from '@/components/referral/FaxConsultDialog.vue';

export default {
  components: {
    SmartPathTransactionsList,
    Loading,
    ToggleButton,
    Button,
    FaxConsultDialog,
  },
  data() {
    return {
      isLoading: false,
      selectedTransactionId: null,
      showFaxConsultDialog: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'isSpecialist', 'actingUser', 'smartPathTransactions', 'selectedSmartPath']),
  },
  methods: {
    ...mapActions(['getSmartPathTransactions', 'saveSmartPath', 'downloadSmartPathResult', 'toggleTransactionSeen']),
    viewTransaction(transactionId) {
      this.$router.push({ path: '/reviewresult/' + transactionId });
    },
    toggleRead(transactionId) {
      this.toggleTransactionSeen({ transaction_id: transactionId })
        .then(() => {})
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
    downloadResult(transactionId) {
      this.downloadSmartPathResult(transactionId).catch(() => {
        this.showGenericErrorToast();
      });
    },
    faxSmartPathResultOnClick(transactionId) {
      this.selectedTransactionId = transactionId;
      this.showFaxConsultDialog = true;
    },
  },
  created() {
    this.isLoading = true;
    this.getSmartPathTransactions(this.$route.params.smartPathId)
      .then(() => {
        // sort by has_seen, then sort by date
        this.smartPathTransactions.sort((a, b) => a.contributor_has_seen - b.contributor_has_seen || new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
        this.showGenericErrorToast();
      });
  },
};
</script>

<style></style>
