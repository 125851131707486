<template>
  <div class="p-4privacy-content-left">
    <h2 class="text-2xl font-bold">Platform Privacy Policy</h2>
    <p><span>Last Updated: Jan 10, 2022.</span></p>
    <p><span>Effective Date: Jan 10, 2022.</span></p>
    <p class="privacy-content-item">
      <span>Virtual Hallway Consults Inc. (“Virtual Hallway”, “Us”, “We”, or “Our”) operates the </span><span><a href="https://www.google.com/url?q=https://virtualhallway.ca&amp;sa=D&amp;source=editors&amp;ust=1621892094010000&amp;usg=AOvVaw1Au635woUkk53R2fSHFEfM">www.virtualhallway.ca</a></span
      ><span>&nbsp;Website (the “Website”), in which users may sign up for or subscribe to Our proprietary software as a service platform designed to virtually connect Primary Care Providers and Specialists (the “Platform”). This Platform Privacy Policy (the “Policy”) informs users of the Platform (“You” or “Your”) of Our policies regarding the collection, use, and disclosure of personally identifiable information and personal health information when You use the Platform and the choices You have associated with the data You submitted to Us. We use Your data to provide and improve the Platform. </span>
    </p>
    <p class="privacy-content-item">
      <span>Unless otherwise stated herein, defined terms in this Policy have the same meaning as set forth in Our End User License Agreement (“EULA”).</span>
    </p>
    <p class="privacy-content-item">
      <span>By using the Platform, You agree to the collection and use of information under this Policy.</span>
    </p>
    <ol class="privacy-number-title" start="1">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Policy Scope</span>
      </li>
    </ol>
    <ol type="a" start="1">
      <li class="privacy-letter-list">
        <span>This Policy describes:</span>
      </li>
    </ol>
    <ol type="i" start="1">
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--1">How We collect, use, disclose, and protect any personally identifiable information and personal health information submitted by You through the Platform.</span>
      </li>
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--2">Describes the types of information We may collect from You or that You may provide when You visit the Platform.</span>
      </li>
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--3">Our practices for collecting, using, maintaining, protecting, and disclosing that information.</span>
      </li>
    </ol>
    <ol type="a" start="2">
      <li class="privacy-letter-list">
        <span>This Policy applies to information We collect, use, or disclose about You:</span>
      </li>
    </ol>
    <ol type="i" start="4">
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--2">On the Platform.</span>
      </li>
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--1">In email, text, and other electronic messages between You and Virtual Hallway in relation to the Platform.</span>
      </li>
    </ol>

    <ol type="a" start="3">
      <li class="privacy-letter-list">
        <span class="privacy-sub-heading">This Policy DOES NOT include any policies regarding Our collection, use, and disclosure of personal information or any information through Your &nbsp;interaction with non-Platform part of the Website, which is governed by Our General Privacy Policy, available at (</span><span class="privacy-sub-heading"><a href="https://www.google.com/url?q=https://virtualhallway.ca/privacy-policy&amp;sa=D&amp;source=editors&amp;ust=1621892094012000&amp;usg=AOvVaw137mfpFSqhcwP03b-7bl-p">www.virtualhallway.ca/privacy-policy</a></span
        ><span class="privacy-sub-heading">). Your communication with Us regarding the non-Platform part of the Website is also covered by the General Privacy Policy. In the event of a conflict between this Policy and the General Privacy Policy, this Policy must prevail.</span>
      </li>
    </ol>
    <ol type="a" start="4">
      <li class="privacy-letter-list">
        <span>This policy DOES NOT apply to information that You provide to or is collected by any third party, through any application or content (including advertising) that may link to or be accessible from or on the Platform. </span>
      </li>
    </ol>
    <ol type="a" start="5">
      <li class="privacy-letter-list">
        <span>We will only use Your personal information in accordance with this Policy unless otherwise required by applicable law. We take steps to ensure that the personal information that We collect from You is adequate, relevant, not excessive, and used for limited purposes. Privacy laws in Canada generally define “personal information” as any information about an identifiable individual, which includes information that can be used on its own or with other information to identify, contact, or locate a single person. For this Policy, personal information also includes “personal health information”/"health information", which has the same meaning under the Nova Scotia Personal Health Information Act (PHIA), the Ontario Personal Health Information Protection Act (PHIPA) and the Alberta Health Information Act (HIA) as amended from time to time.</span>
      </li>
    </ol>
    <ol type="a" start="6">
      <li class="privacy-letter-list"><span class="privacy-sub-heading">Please read this policy carefully to understand Our policies and practices for collecting, processing, and storing Your information. If You do not agree with Our policies and practices, Your choice is not to use Our Platform. </span><span>By accessing or using this Platform, You indicate that You understand, accept, and consent to the practices described in this Policy. This Policy may change from time to time (see Changes to This Privacy Policy below). Your continued use of the Platform after We make changes indicates that You accept and consent to those changes, so please check the policy periodically for updates.</span></li>
    </ol>
    <ol class="privacy-number-title" start="2">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Information Collection and Use</span>
      </li>
    </ol>
    <p>
      <span>We collect several different types of information for various purposes to provide and improve Our Platform to You. </span>
    </p>
    <p>
      <span class="under-line">Types of Information Collected</span>
    </p>
    <ol type="a" start="1">
      <li class="privacy-letter-list">
        <span>Personal Data</span>
      </li>
    </ol>
    <ol type="i" start="1">
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--1">While using Our Platform, We may ask You to provide Us with certain personally identifiable information. Personal Data may include but is not limited to:</span>
      </li>
    </ol>
    <ol type="1" start="1">
      <li class="privacy-roman-numerals-sub-list">
        <span>Data be used to contact or identify You, such as Email address, First name and last name, Your device’s Internet Protocol address, phone number, and Your address and postal code; and</span>
      </li>
      <li class="privacy-roman-numerals-sub-list">
        <span>For identity verification and fraud prevention, We may ask You to provide Us with Your government-issued identification, proof of insurance and liability coverage, and other relevant information from time to time.</span>
      </li>
    </ol>
    <ol type="i" start="2">
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--2">We may collect additional Personal Data when You use the Platform as You may supply Us with personally identifiable information and personal health information, which include:</span>
      </li>
    </ol>
    <ol type="1" start="1">
      <li class="privacy-roman-numerals-sub-list">
        <span>Your Patient’s information, including their personal health information, such as name, diagnosis, health card number, date of birth, MSI/Health Society Code;</span>
      </li>
      <li class="privacy-roman-numerals-sub-list">
        <span>Names of Primary Care Provider and Specialist; and</span>
      </li>
      <li class="privacy-roman-numerals-sub-list">
        <span>Other relevant information such as start and end time of the consultation.</span>
      </li>
    </ol>
    <p class="privacy-content-item">
      <span>In any case, We will only collect and maintain Patient personal health information that You provide to Us in relation to the services of the Platform.</span>
    </p>
    <ol type="a" start="1">
      <li class="privacy-letter-list">
        <span>Business and Professional Practice Data</span>
      </li>
    </ol>
    <ol type="i" start="1">
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--1">We may also collect Business and Professional Practice Data from You for Your registration for or subscription to the Platform and for verifying Your professional practice status. </span>
      </li>
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--2">Data we collected includes Your business phone number, province of practice, Your practitioner type, professional society membership number, and Your address of practice. </span>
      </li>
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--3">We may also collect necessary billing information from Your practice, such as Your business’ bank account information, name of financial institution, and Your business address, so that We can act as Your agent in collecting the bill payments from MSI. </span>
      </li>
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--2">If You subscribe to the Platform, We may also collect Your credit card information for facilitate Your subscription fees to Us, including (Last 4 digits) of Your credit card number, credit card type, billing address and postal code.</span>
      </li>
    </ol>
    <ol type="a" start="2">
      <li class="privacy-letter-list"><span>Usage Data</span></li>
    </ol>
    <p class="privacy-roman-numerals">
      <span>We may also collect information relating to how You access and use the Platform (“Usage Data”). This Usage Data may include information such as browser type, browser version, the pages of Our Platform that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data. </span>
    </p>
    <ol type="a" start="3">
      <li class="privacy-letter-list">
        <span>Tracking &amp; Cookies Data</span>
      </li>
    </ol>
    <p class="privacy-roman-numerals">
      <span>We use cookies and similar tracking technologies to track the activity on Our Platform and hold certain information. Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to Your browser from a website and stored on Your device. Tracking technologies also used are web beacons, tags, and scripts to collect and track information and to improve and analyze the Platform. You can instruct Your browser to refuse all cookies or to indicate when a cookie is being sent. However, if You do not accept cookies, You may not be able to use some portions of Our Platform. Examples of Cookies We use: </span>
    </p>
    <ol type="i" start="1">
      <li class="privacy-roman-numerals"><span class="privacy-roman-numerals-spacing--1 privacy-sub-heading">Session Cookies.</span><span>&nbsp;We use Session Cookies to operate Our Platform.</span></li>
      <li class="privacy-roman-numerals"><span class="privacy-roman-numerals-spacing--2 privacy-sub-heading">Preference Cookies.</span><span>&nbsp;We use Preference Cookies to remember Your preferences and various settings.</span></li>
      <li class="privacy-roman-numerals"><span class="privacy-roman-numerals-spacing--3 privacy-sub-heading">Security Cookies.</span><span>&nbsp;We use Security Cookies for security purposes.</span></li>
    </ol>
    <ol class="privacy-number-title" start="2">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">How We Collect Information About You</span>
      </li>
    </ol>
    <p class="privacy-content-item">
      <span>We use different methods to collect Your information, including through:</span>
    </p>
    <ol type="a" start="1">
      <li class="privacy-letter-list">
        <span>Direct interactions with You when You provide it to us, for example, by filling in forms or corresponding with Us by phone, email, or otherwise. </span>
      </li>
      <li class="privacy-letter-list">
        <span>Automated technologies or interactions, as You navigate through the Platform. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.</span>
      </li>
      <li class="privacy-letter-list">
        <span>Third parties or publicly available sources, for example, Our business or advertising partners. </span>
      </li>
    </ol>
    <ol class="privacy-number-title" start="3">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Information You Provide to Us </span>
      </li>
    </ol>
    <p class="privacy-content-item">
      <span>The information We collect directly from You on or through Our Platform may include:</span>
    </p>
    <ol type="a" start="1">
      <li class="privacy-letter-list">
        <span>Information that You provide by filling in forms on Our Platform, interacting with the Platform’s functionalities, using Our Platform to facilitate consultation with a Specialist or a Primary Care Provider, uploading to the Platform, posting any material, making payments to Our Platform services, or requesting further services. We may also ask You for information when You enter a conference or promotion sponsored by us, and when You report a problem to Us in relation to the Platform.</span>
      </li>
      <li class="privacy-letter-list">
        <span>Records and copies of Your correspondence (including email addresses), if You contact Us.</span>
      </li>
    </ol>
    <ol class="privacy-number-title" start="4">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Use of Your Information</span>
      </li>
    </ol>
    <ol type="a" start="1">
      <li class="privacy-letter-list">
        <span>Virtual Hallway uses the collected data for various purposes: </span>
      </li>
    </ol>
    <ol type="i" start="1">
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--1">To provide Our medical consultation management services on the Platform and maintain the Platform;</span>
      </li>
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--2">To notify You about changes to the Platform;</span>
      </li>
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--3">To allow You to participate in interactive features of Our Platform when You choose to do so;</span>
      </li>
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--2">To provide customer care and support;</span>
      </li>
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--1">To process payments and medical bills on behalf of Your business;</span>
      </li>
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--2">To receive payments from you;</span>
      </li>
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--3">To provide analysis or valuable information so that We can improve the Platform;</span>
      </li>
      <li class="privacy-roman-numerals">
        <span>To monitor the usage of the Platform;</span>
      </li>
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--2">To detect, prevent and address technical issues; and</span>
      </li>
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--1">For any other purpose with Your consent.</span>
      </li>
    </ol>
    <p class="privacy-content-item">
      <span>We may also use Your information to contact You about Our own and third-parties' goods and services that may be of interest to You, as permitted by law. If You do not want Us to use Your information in this way, please e-mail Us at support@virtualhallway.ca. For more information, see Your Choices Section below.</span>
    </p>
    <p class="privacy-content-item">
      <span>We will only share personal health information to a third-party entity and any other person in accordance with applicable privacy and security laws and with the terms and conditions of the EULA. </span>
    </p>
    <p class="privacy-content-item">
      <span>We may use the information We have collected from You to enable Us to display advertisements to Our advertisers' target audiences. Even though We do not disclose Your personal information for these purposes without Your consent, if You click on or otherwise interact with an advertisement on the Website, the advertiser may collect information about You, in which We do not have control. For example, the advertiser may identify You as their targeted audience.</span>
    </p>
    <ol class="privacy-number-title" start="5">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Data Processing and Transfer</span>
      </li>
    </ol>
    <p class="privacy-content-item">
      <span>Please note that We store and process Your personal information, including all Personal Data, in Canada. If You are located outside Canada and choose to provide information to us, Your consent to this Policy followed by Your submission of such information represents Your agreement to that transfer. </span>
    </p>
    <p class="privacy-content-item">
      <span>We may transfer personal information that We collect or that You provide as described in this policy to contractors, Our Service Providers (defined hereafter), and other third parties We use to support Our business (such as analytics and search engine providers that assist Us with Website improvement and optimization) and who are contractually obligated to keep personal information confidential, use it only for the purposes for which We disclose it to them, and to process the personal information with the same standards set out in this Policy.</span>
    </p>
    <ol class="privacy-number-title" start="6">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Disclosure of Your Information</span>
      </li>
    </ol>
    <ol type="a" start="1">
      <li class="privacy-letter-list">
        <span>Virtual Hallway may disclose aggregated information about Our users, and information that does not identify any individual, without restriction.</span>
      </li>
      <li class="privacy-letter-list">
        <span>Virtual Hallway may disclose personally identifiable information that We collect or You provide in the good faith belief that such action is necessary: </span>
      </li>
    </ol>
    <ol type="i" start="1">
      <li class="privacy-roman-numerals">
        <span>To comply with Our legal obligations, including to respond to any government or regulatory request, in accordance with applicable law;</span>
      </li>
      <li class="privacy-roman-numerals">
        <span>To provide services of this Platform in accordance with the EULA, such as facilitating the consultation between the Primary Care Provider and the Specialist and facilitating medical billing between You and Billing Agent;</span>
      </li>
      <li class="privacy-roman-numerals">
        <span>To fulfill the purpose for which You provide it;</span>
      </li>
      <li class="privacy-roman-numerals">
        <span>To enforce or apply Our terms and conditions and other agreements, including for billing and collection purposes.</span>
      </li>
      <li class="privacy-roman-numerals">
        <span>In accordance with applicable law, to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Virtual Hallway’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Virtual Hallway is among the assets transferred;</span>
      </li>
      <li class="privacy-roman-numerals">
        <span>To protect and defend the rights or property of Virtual Hallway;</span>
      </li>
      <li class="privacy-roman-numerals">
        <span>To prevent or investigate possible wrongdoing in connection with Our services;</span>
      </li>
      <li class="privacy-roman-numerals">
        <span>To protect the personal safety of users of the Platform or the public; and</span>
      </li>
      <li class="privacy-roman-numerals">
        <span>To protect against Our legal liability.</span>
      </li>
    </ol>
    <ol class="privacy-number-title" start="7">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Security of Data</span>
      </li>
    </ol>
    <ol type="a" start="1">
      <li class="privacy-letter-list">
        <span>The security of your personal information is very important to us. We use commercially acceptable means and industrial standard measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. We store all information you provide to us behind firewalls on secure servers hosted by our Service Provider. Any payment transactions and personal information You provided on or through the Platform will be encrypted using SSL technology.</span>
      </li>
      <li class="privacy-letter-list">
        <span>The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Platform, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. </span>
      </li>
      <li class="privacy-letter-list">
        <span>Unfortunately, the transmission of information via the Internet or method of electronic storage is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.</span>
      </li>
    </ol>
    <ol class="privacy-number-title" start="8">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Retention of Data</span>
      </li>
    </ol>
    <ol type="a" start="1">
      <li class="privacy-letter-list">
        <span>Except as otherwise permitted or required by applicable law or regulation, We will only retain Your personal information and Patient personal health information for as long as necessary to fulfill the purposes We collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. Under some circumstances We may anonymize Your personal information so that it can no longer be associated with You. We reserve the right to use such anonymous and de-identified data for any legitimate business purpose without further notice to You or Your consent.</span>
      </li>
      <li class="privacy-letter-list">
        <span>Please refer to Our EULA as it sets forth specific rules with regard to the retention of Patient personal health information.</span>
      </li>
    </ol>
    <ol class="privacy-number-title" start="9">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Your Choices</span>
      </li>
    </ol>
    <p class="privacy-content-item">
      <span>We strive to provide You with choices regarding the personal information You provide to Us. We have created mechanisms to provide You with the following control over Your information: </span>
    </p>
    <ol type="a" start="1">
      <li class="privacy-letter-list"><span class="privacy-sub-heading">Tracking Technologies and Advertising.</span><span>&nbsp;You can set Your browser to refuse all or some browser cookies, or to alert You when cookies are being sent. If You disable or refuse cookies, please note that some parts of the Platform may not be accessible or may not function properly.</span></li>
      <li class="privacy-letter-list">
        <span class="privacy-sub-heading">Promotional Offers from Us.</span><span>&nbsp;If You have opted in to receive certain emails from Us but no longer wish to have Your &nbsp;contact information used by the Company to promote Our own or third parties' products or services, You can opt-out by sending Us an email stating Your &nbsp;request to </span><span><a href="mailto:support@virtualhallway.ca">support@virtualhallway.ca</a></span
        ><span>. If we have sent You a promotional email, You may unsubscribe by clicking the unsubscribe link we have included in the email or change Your preferences in Your account profile. </span>
      </li>
      <li class="privacy-letter-list">
        <span class="privacy-sub-heading">Third Party and Internet-based Advertising.</span><span>&nbsp; We will not provide Your personal information to third parties, except for the purpose to provide Our services to You and otherwise states in the EULA or herein. However, we cannot control third parties' collection or use of Your information to serve interest-based advertising. However, these third parties may provide You with ways to choose not to have Your information collected or used in this way. You can opt out of several third-party ad servers' and networks' cookies simultaneously by using an opt-out tool created by the </span><span><a href="https://www.google.com/url?q=https://youradchoices.ca/&amp;sa=D&amp;source=editors&amp;ust=1621892094023000&amp;usg=AOvVaw0RIpQWnD_z5UGOdoTdrvLs">Digital Advertising Alliance of Canada</a></span
        ><span>. You can also access these websites to learn more about online behavioural advertising and how to stop websites from placing cookies on Your device. Opting out of a network does not mean You will no longer receive online advertising. It does mean that the network from which You opted out will no longer deliver ads tailored to Your web preferences and usage patterns.</span>
      </li>
    </ol>
    <ol class="privacy-number-title" start="10">
      <li class="spcaing">
        <span class="privacy-sub-heading">Accessing and Correcting Your Personal Information</span>
      </li>
    </ol>
    <ol type="a" start="1">
      <li class="privacy-letter-list">
        <span>By law You have the right to request access to and to correct the personal information that we hold about you. You may review and change some of the personal information by logging into the Platform through the Website and visiting Your account profile page. Please keep Us informed if Your personal information changes. For any personal information that You cannot change by visiting Your account profile page, You may send Us an email at </span><span><a href="mailto:privacy@virtualhallway.ca">privacy@virtualhallway.ca</a></span
        ><span>&nbsp;to request access to, correct, or delete any personal information that You have provided to Us.</span>
      </li>
      <li class="privacy-letter-list">
        <span>Subject to applicable privacy law, a Patient may request access to their personal information and personal health information hosted by Us by sending Us an email at </span><span><a href="mailto:privacy@virtualhallway.ca">privacy@virtualhallway.ca</a></span
        ><span>&nbsp;</span><span>to request access to, correct, or delete any personal information that You have provided to Us.</span>
      </li>
      <li class="privacy-letter-list">
        <span>For either situation in paragraph (a) or (b) above, We may not accommodate a request to change information if We believe the change would violate any law or legal requirement or cause the information to be incorrect. </span>
      </li>
      <li class="privacy-letter-list">
        <span>In certain circumstances We may need to delete Your account to delete Your personal information. In such cases, You consent to Us deleting Your account for this purpose.</span>
      </li>
      <li class="privacy-letter-list">
        <span>We may request specific information from the person requesting access to their personal information to help Us confirm their identity and their right to access, and to provide You with the personal information that We hold about them (or their Patient) or make their requested changes. If We cannot provide You with access to the personal information, We will inform them of the reasons why, subject to any legal or regulatory restrictions. We will provide access to their personal information, subject to exceptions set out in applicable privacy legislation.</span>
      </li>
      <li class="privacy-letter-list">
        <span>If any person is concerned about Our response or would like to correct the information provided, the person may contact Our Privacy Officer at </span><span><a href="mailto:privacy@virtualhallway.ca">privacy@virtualhallway.ca</a></span
        ><span>.</span>
      </li>
    </ol>
    <ol class="privacy-number-title" start="11">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Withdraw Consent</span>
      </li>
    </ol>
    <p class="privacy-content-item">
      <span>Where a person has provided consent to the collection, use, and transfer of the person’s personal information, the person may have the legal right to withdraw consent under certain circumstances. To withdraw consent, if applicable, contact Us at </span><span><a href="mailto:privacy@virtualhallway.ca">privacy@virtualhallway.ca</a></span
      ><span>. Please note that if the person withdraws consent, We may not be able to provide the person with a particular product or service. We will explain the impact to the person at the time to help You with Your decision.</span>
    </p>

    <ol class="privacy-number-title" start="12">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Third Party Providers</span>
      </li>
    </ol>
    <ol type="a" start="1">
      <li class="privacy-letter-list">
        <span>From time to time, We may engage with or employ third-party entities and individuals (“Service Providers”) for maintaining functionalities of and making changes to the Platform, to host information and data of the Platform, to process payment on behalf of You, or to collect your payment of subscription fees of the Platform. </span>
      </li>
      <li class="privacy-letter-list">
        <span>Service Providers have access to personal information submitted by You or collected by Us only to perform these tasks on Our behalf and are obligated under contractual arrangements not to disclose or use it for any other purpose and to process the personal information with the same standards set out in this Policy.</span>
      </li>
      <li class="privacy-letter-list">
        <span>You acknowledge that while Virtual Hallway strives to protect privacy and security of personal information You submit or We collect, We cannot guarantee our Service Providers will adhere to the same level of protection and security.</span>
      </li>
      <li class="privacy-letter-list">
        <span>You further acknowledge that, from time to time, Service Providers may also access or process de-identified Personal Information to assist Us in analyzing how Our Website is used.</span>
      </li>
    </ol>

    <ol class="privacy-number-title" start="13">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Links to Other Sites</span>
      </li>
    </ol>
    <p class="privacy-content-item">
      <span>The Platform may include plug-ins, services, social networks, applications, or links to third-party websites. Clicking on those links or enabling those connections may allow the third party to collect or share data about You. If You follow a link to a third-party website or engage a third-party plug-in, please note that these third parties have their own privacy policies, and We do not accept any responsibility or liability for the practices of third-party websites or their policies. We do not control these third-party websites or applications, and We encourage You to read the privacy policy of every website or application You visit. </span>
    </p>
    <ol class="privacy-number-title" start="14">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Children’s Privacy</span>
      </li>
    </ol>
    <ol type="a" start="1">
      <li class="privacy-letter-list">
        <span>You represent and warrant that You are over the majority age of your jurisdiction or age of 19 (whichever is higher) when You access or use the Platform. Our Platform does not address or intend to address any User under the age of the majority of Your jurisdiction; or any User under 19. We do not knowingly collect personal information from such user.</span>
      </li>
      <li class="privacy-letter-list">
        <span>We collect personal information, including personal health information, of a child Patient under age of 13, only if the child’s parent(s) or legal guardian(s) have provided informed consent to the Primary Care Provider for the child’s personal information to be stored, processed, or transferred through the Platform. If We learn We have collected or received personal information from a Patient who is a child under the age of 13 without verification of an informed parental consent, we will delete that information. If You believe We might have any information from or about a child under the age of 13, please contact Virtual Hallway at </span><span><a href="mailto:privacy@virtualhallway.ca">privacy@virtualhallway.ca</a></span
        ><span>.</span>
      </li>
    </ol>
    <ol class="privacy-number-title" start="15">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Changes to This Policy</span>
      </li>
    </ol>
    <p class="privacy-content-item">
      <span>We may update Our Policy from time to time. We will notify You of any changes by posting the new Policy on this page. We will let You know via email and/or a prominent notice on Our Platform, prior to the change becoming effective and update the “Effective Date” at the top of this Policy. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page. </span>
    </p>
    <ol class="privacy-number-title" start="16">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Contact Us</span>
      </li>
    </ol>
    <p class="privacy-content-item">
      <span>If You have any questions about this Platform Privacy Policy, please contact Us by email: </span><span><a href="mailto:privacy@virtualhallway.ca">privacy@virtualhallway.ca</a></span
      ><span>.</span>
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
