<template>
  <div class="w-full" v-if="!isLoading">
    <div class="relative w-full">
      <div class="w-full text-left mt-6 font-semibold px-3 font-display">{{ selectedSpecialty.name }}</div>
      <div class="grid w-full grid-cols-1 gap-3 pt-4 lg:grid-cols-3 xl:grid-cols-4">
        <template v-if="selectedInteractionType === 'phone'">
          <div v-if="anyScheduleAvailable">
            <SpecialistCard :selectedSpecialty="selectedSpecialty" :schedulesOfSpecialists="allSchedulesOfSpecialists" :specialists="specialists" @goToSelectDate="goToSelectDate" @econsultOnClick="econsultOnClick" @bookOnDemand="bookOnDemand" :numberOfAvailableSpecialists="numberOfAvailableSpecialists" />
          </div>
          <div v-else-if="showComingSoonCard" class="position-relative flex w-full cursor-not-allowed flex-col items-center rounded-2xl bg-white shadow">
            <div class="relative z-20 mt-3 flex h-20 w-20 justify-center rounded-full border-4 border-solid border-white bg-gray-500">
              <i class="pi fas fa-user-doctor specialist-card-item-img text-white" style="font-size: 1rem" />
            </div>
            <div class="flex w-full flex-row px-4 text-center">
              <p class="w-full font-bold text-gray-500">Coming Soon</p>
            </div>
            <div class="flex w-full flex-row px-4 pb-4 text-center">
              <p class="w-full text-sm text-gray-500">This specialty will soon be available for consultation.</p>
            </div>
          </div>
        </template>
        <div :style="'--index: ' + index" class="tile animated fade-in-up hover:cursor-pointer" v-for="(user, index) in specialists" :key="user.id" id="selectSpecialist">
          <SpecialistCard :selectedSpecialty="selectedSpecialty" :user="user" :schedulesOfSpecialists="individualSchedulesOfSpecialists[user.id]" @goToSelectDate="goToSelectDate" @econsultOnClick="econsultOnClick" @bookOnDemand="bookOnDemand" :numberOfAvailableSpecialists="numberOfAvailableSpecialists" />
        </div>
      </div>

      <div v-if="lecturesForSelectedSpecialty.length > 0" id="relatedCMELecturesSection">
        <div class="w-full text-left mt-6 font-semibold px-3 font-display">Related CME Lectures</div>
        <div class="grid ml-2 w-full grid-cols-1 gap-3 pt-4 lg:grid-cols-3 xl:grid-cols-3">
          <div v-for="lecture in lecturesForSelectedSpecialty" :key="lecture.id" class="tile animated fade-in-up hover:cursor-pointer">
            <LectureCard :lecture="lecture" buttonText="Watch Now" />
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6 flex justify-items-start rounded-2xl border-1 mb-10 bg-white p-5 shadow" v-if="hasProvinceOfPractice(actingUser, ['NS', 'ON', 'PE']) && isPartOfGeneralVhcHallway(actingUser)">
      <Button @click="goToSelectSpecialty" class="p-button-secondary flex w-28 justify-center" icon="pi pi-caret-left" label="Back"></Button>
    </div>
  </div>
  <div v-else class="flex h-48 w-full flex-grow flex-col items-center justify-end">
    <i class="pi pi-spin pi-spinner" style="font-size: 2.5rem; color: #ef5164"></i>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import SpecialistCard from '@/components/specialist/SpecialistCard.vue';
import moment from 'moment';
import Dropdown from 'primevue/dropdown';

export default {
  props: ['selectedSpecialty'],
  computed: {
    ...mapGetters(['actingUser', 'loggedInUser', 'users', 'schedulesOfSpecialists', 'econsultCounts', 'hasProvinceOfPractice', 'referralToRebook', 'selectedInteractionType', 'availableZones', 'smartPaths', 'tutorialStatus', 'offeredServicesOfUsers', 'specialistFilterValue', 'lecturesForSelectedSpecialty']),
  },
  components: { Button, SpecialistCard, InputText, Dropdown },
  data() {
    return {
      selectedSpecialists: [],
      selectedSchedules: [],
      error: '',
      specialists: [],
      minDate: moment().toDate(),
      maxDate: moment().add(60, 'days').toDate(),
      individualSchedulesOfSpecialists: [],
      anyScheduleAvailable: false,
      showComingSoonCard: false,
      isLoading: false,
      allSchedulesOfSpecialists: [],
      selectedZone: null,
      selectedSort: null,
      numberOfAvailableSpecialists: null,
    };
  },
  emits: ['goToSelectSpecialty', 'goToSelectDate', 'bookEconsult', 'bookOnDemand'],
  methods: {
    ...mapActions(['getAllUsersBySpecialty', 'getEconsultCountsOfUsersBySpecialtyId', 'getUpcomingScheduleWithScheduleBufferOfUser', 'getPublicSmartPaths', 'getOfferedServicesOfUsers', 'getLecturesBySpecialtyId']),
    goToSelectDate(event) {
      this.isLoading = true;
      this.getUpcomingScheduleWithScheduleBufferOfUser({ specialistIds: event.specialists.map((specialist) => specialist.id), smartPathReferral: false })
        .then(() => {
          this.isLoading = false;
          this.$emit('goToSelectDate', {
            specialists: event.specialists,
            schedules: this.schedulesOfSpecialists.flat(),
            selectedSpecialty: this.selectedSpecialty,
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    goToSelectSpecialty() {
      this.$emit('goToSelectSpecialty');
    },
    econsultOnClick(event) {
      this.$emit('bookEconsult', event);
    },
    bookOnDemand(event) {
      this.$emit('bookOnDemand', event);
    },
    filterValueOnChange() {
      this.specialists = this.offeredServicesOfUsers
        .filter((user) => {
          if (this.specialistFilterValue === '') {
            return true;
          }
          return user.first_name.toLowerCase().startsWith(this.specialistFilterValue.toLowerCase()) || user.last_name.toLowerCase().startsWith(this.specialistFilterValue.toLowerCase());
        })
        .filter((user) => {
          if (!this.selectedZone) {
            return true;
          }
          return user.zone?.name === this.selectedZone;
        })
        .sort((a, b) => {
          if (!this.selectedSort) {
            return 0;
          }
          if (a.waitlist_in_days && b.waitlist_in_days) {
            if (this.selectedSort === 'Shortest Wait List') {
              return a.waitlist_in_days < b.waitlist_in_days ? -1 : 1;
            } else if (this.selectedSort === 'Longest Wait List') {
              return a.waitlist_in_days < b.waitlist_in_days ? 1 : -1;
            }
          } else if (a.waitlist_in_days) {
            return -1;
          } else if (b.waitlist_in_days) {
            return 1;
          }
          return 0;
        });
    },
    clearFilter() {
      this.selectedZone = null;
      this.selectedSort = null;
      this.filterValue = '';
      this.filterValueOnChange();
    },
  },
  created() {
    window.scrollTo(0, 0);
    this.isLoading = true;
    this.getOfferedServicesOfUsers({ specialty_id: this.selectedSpecialty.id }).then(() => {
      this.specialists = this.offeredServicesOfUsers;
      this.anyScheduleAvailable = this.specialists.find((sp) => sp.phoneConsult || sp.eConsult || sp.onDemandConsult);

      let promises = [];

      promises.push(
        new Promise((resolve, reject) => {
          this.getLecturesBySpecialtyId({ specialty_id: this.selectedSpecialty.id })
            .then(() => {
              resolve('success');
            })
            .catch((error) => {
              reject(error);
            });
        })
      );

      // Check if loggedInUser has smartpath access.
      // If so, round up all specialists ids and call the getUsersPublicSmartpaths method.
      // var smart_path_access = this.loggedInUser.org_units.find((org_unit) => org_unit.name === 'Pathways Pilot');
      var smart_path_access = this.loggedInUser.practice_province.name === 'Nova Scotia';
      if (smart_path_access) {
        promises.push(
          new Promise((resolve, reject) => {
            this.getPublicSmartPaths({ user_ids: this.offeredServicesOfUsers.map((specialist) => specialist.id) })
              .then(() => {
                resolve('success');
              })
              .catch((error) => {
                reject(error);
              });
          })
        );
      }

      if (this.specialists.find((sp) => sp.eConsult)) {
        promises.push(
          new Promise((resolve, reject) => {
            this.getEconsultCountsOfUsersBySpecialtyId(this.selectedSpecialty.id)
              .then(() => {
                resolve('success');
              })
              .catch((error) => {
                reject(error);
              });
          })
        );
      }

      Promise.all(promises)
        .then(() => {
          if (!this.hasProvinceOfPractice(this.actingUser, ['NS', 'ON', 'PE'])) {
            this.showComingSoonCard = !this.anyScheduleAvailable;
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    });
  },
  watch: {
    specialistFilterValue() {
      this.filterValueOnChange();
    },
  },
};
</script>

<style>
.make-referral-scroll-list {
  overflow-y: scroll;
  max-height: 75%;
  padding-right: 20px;
}
</style>
