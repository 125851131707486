<template>
  <h2 class="admin-heading mb-4 flex-row">{{ chart_name }}</h2>
  <PieChart style="height: 300px" class="bg-white" :chartData="data" :options="options" />
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { PieChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

export default defineComponent({
  components: {
    PieChart,
  },
  props: ['referrals', 'chart_name'],
  setup(props) {
    const referral_specialty_counts = referralSpecialtyCounts(props.referrals);

    let labels = [];
    let counts = [];
    let sliceColours = [];
    for (let i = 0; i < referral_specialty_counts.length; i++) {
      labels.push(referral_specialty_counts[i]['specialty']);
      counts.push(referral_specialty_counts[i]['count']);
      // colour randomizer, taken from the genius of Franck Freilburger
      // referenced here: https://stackoverflow.com/questions/1152024/best-way-to-generate-a-random-color-in-javascript#1152508
      var colour = '#' + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);
      sliceColours.push(colour);
    }

    const data = computed(() => ({
      labels: labels,
      datasets: [
        {
          data: counts, // counts
          backgroundColor: sliceColours,
        },
      ],
    }));

    const options = ref({
      responsive: true,
      plugins: {
        legend: {
          display: true,
        },
        title: {
          display: false,
        },
      },
    });

    function referralSpecialtyCounts(referrals) {
      var specialty_bins = [];
      referrals.forEach((referral) => {
        var found = 0;
        for (var i = 0; i < specialty_bins.length; i++) {
          if (specialty_bins[i].specialty == referral.specialty.name && found == 0) {
            specialty_bins[i].count++;
            found = 1;
          }
        }
        if (found == 0) {
          specialty_bins.push({ specialty: referral.specialty.name, count: 1 });
        }
      });
      return specialty_bins;
    }

    return {
      data,
      options,
    };
  },
});
</script>
