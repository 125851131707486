<template>
  <h2 class="admin-heading mb-4 flex-row">{{ chart_name }}</h2>
  <LineChart style="height: 300px" class="bg-white" :chartData="data" :options="options" />
</template>

<script>
import moment from 'moment';
import { computed, defineComponent, ref } from 'vue';
import { LineChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

export default defineComponent({
  components: {
    LineChart,
  },
  props: ['referrals', 'chart_name'],
  setup(props) {
    const weekly_referral_counts = weeklyReferralCounts(props.referrals);

    let dates = [];
    let counts = [];
    for (let i = 0; i < weekly_referral_counts.length; i++) {
      dates.push(weekly_referral_counts[i]['week']);
      counts.push(weekly_referral_counts[i]['referral_count']);
    }

    const data = computed(() => ({
      labels: dates, // dates
      //labels: {'dates': dates, 'test': 'test'},
      datasets: [
        {
          data: counts, // counts
          backgroundColor: '#6798F0',
          borderColor: '#6798F0',
          //borderWidth: '50px',
        },
      ],
    }));

    // const lineRef = ref();

    const options = ref({
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            stepSize: 1,
          },
        },
      },
    });

    function weeklyReferralCounts(referrals) {
      const weekly_referral_bins = [];
      referrals.sort((a, b) => a.start_datetime - b.start_datetime);

      var current = new moment(referrals[0].start_datetime);
      var end = new Date();

      var referrals_index = 0;
      let referral_count = 0;

      while (current.isBefore(end)) {
        // let endOfWeek = { ...current };
        var endOfWeek = new moment(current);
        endOfWeek.add(6, 'days');

        while (referrals_index < referrals.length && new moment(referrals[referrals_index].start_datetime).isBefore(endOfWeek)) {
          referral_count++;
          referrals_index++;
        }

        weekly_referral_bins.push({ week: current.format('MM-DD-YYYY'), referral_count: referral_count });
        current.add(7, 'days');
      }
      return weekly_referral_bins;
    }

    return {
      data,
      // lineRef,
      options,
    };
  },
});
</script>
