<template>
  <div class="main-inner-wrap">
    <transition name="slide-up-fade">
      <div class="bg-white rounded-3xl max-w-xl w-full shadow p-6 pt-8" v-if="toggleTransition">
        <div class="justify-center w-full items-center text-center flex flex-col mt-4">
          <img class="w-60" src="@/assets/Virtual-Hallway-Logo-Horizontal.svg" alt="" />
        </div>
        <form method="post" @submit.prevent="submit">
          <h4 class="font-medium text-gray-500 text-xl mt-2">Set Password</h4>
          <div v-if="!codeIsInvalid">
            <span class="p-float-label my-6">
              <Password name="password" id="password" v-model="password" :toggleMask="true" :feedback="false" required :class="['public-input', { 'p-invalid': errors.length > 0 }]" />
              <label for="password" class="public-input text-left">Password <span class="error">*&nbsp;</span></label>
            </span>

            <span class="p-float-label my-6">
              <Password name="confirmPassword" id="confirmPassword" v-model="confirmPassword" :feedback="false" :toggleMask="true" required :class="['public-input', { 'p-invalid': errors.length > 0 }]" />
              <label for="confirmPassword" class="public-input text-left">Confirm Password <span class="error">*&nbsp;</span></label>
            </span>
            <div class="h-20 mt-4 w-full flex items-center text-xs">
              <div class="w-2/3 text-left pl-3">
                <div name="password-strength-indicator" class="flex flex-row items-center mb-2">
                  <i :class="['pi', password.length < 10 ? 'pi-times-circle' : 'pi-check-circle', password.length < 10 ? 'error' : 'success', 'pr-1']" style="fontsize: 0.8rem"></i>
                  <div class="text-xs">Ten characters minimum</div>
                  <br />
                </div>

                <div name="password-strength-indicator" class="flex flex-row items-center mb-2"><i :class="['pi', !regex.test(password) ? 'pi-times-circle' : 'pi-check-circle', !regex.test(password) ? 'error' : 'success', 'pr-1']" style="fontsize: 0.8rem"></i>One special character<br /></div>
              </div>
              <div class="w-2/3 text-left pl-3">
                <div name="password-strength-indicator" class="flex flex-row items-center mb-2"><i :class="['pi', !/[a-z]/.test(password) ? 'pi-times-circle' : 'pi-check-circle', !/[a-z]/.test(password) ? 'error' : 'success', 'pr-1']" style="fontsize: 0.8rem"></i><span class="text-xs">One lowercase letter </span><br /></div>

                <div name="password-strength-indicator" class="flex flex-row items-center mb-2"><i :class="['pi', !/[A-Z]/.test(password) ? 'pi-times-circle' : 'pi-check-circle', !/[A-Z]/.test(password) ? 'error' : 'success', 'pr-1']" style="fontsize: 0.8rem"></i>One uppercase letter<br /></div>
              </div>
              <div class="w-2/3 text-left pl-3">
                <div name="password-strength-indicator" class="flex flex-row items-center mb-2"><i :class="['pi', !/[0-9]/.test(password) ? 'pi-times-circle' : 'pi-check-circle', !/[0-9]/.test(password) ? 'error' : 'success', 'pr-1']" style="fontsize: 0.8rem"></i>One number<br /></div>

                <div name="password-strength-indicator" class="flex flex-row items-center mb-2"><i :class="['pi', password !== confirmPassword || password === '' ? 'pi-times-circle' : 'pi-check-circle', password !== confirmPassword || password === '' ? 'error' : 'success', 'pr-1']" style="fontsize: 0.8rem"></i>Passwords match<br /></div>
              </div>
            </div>
            <button type="submit" class="btn btn--main my-5">Set Password</button>
            <ul v-for="(error, i) in errors" :key="i" class="error w-full flex justify-center">
              <li>{{ error }}</li>
            </ul>
          </div>
          <div v-else class="error p-5">
            <ul v-for="(error, i) in errors" :key="i" class="error">
              <li>{{ error }}</li>
            </ul>
          </div>
        </form>
      </div>
    </transition>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import { mapActions } from 'vuex';
import Password from 'primevue/password';
import Loading from 'vue-loading-overlay';

export default {
  components: { Password, Loading },
  data() {
    return {
      password: '',
      confirmPassword: '',
      errors: [],
      regex: /[\s~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?()._]/,
      toggleTransition: false,
      isLoading: true,
      codeIsInvalid: false,
    };
  },
  created() {
    this.verifyPasswordReset({
      userId: this.$route.params.userId,
      resetCode: this.$route.params.code,
    })
      .then(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        this.isLoading = false;
        if (error.status == 422) {
          this.codeIsInvalid = true;
          for (const key in error.data.errors) {
            // If link is expired, no need to display other errors as a new link has been sent.
            if (key == 'reset_code_expired') {
              this.errors = [error.data.errors[key][0]];
              break;
            }
            // Otherwise, show all other error values e.g., code does not exist etc. which
            // wouldn't really happen unless somebody tampers with the link.
            error.data.errors[key].forEach((element) => {
              this.errors.push(element);
            });
          }
        } else {
          this.showGenericErrorToast();
        }
      });
  },
  methods: {
    ...mapActions(['verifyPasswordReset', 'resetPassword']),
    submit() {
      this.$toast.removeAllGroups();
      this.resetPassword({
        password: this.password,
        confirmPassword: this.confirmPassword,
        userId: this.$route.params.userId,
        resetCode: this.$route.params.code,
      })
        .then((res) => {
          this.errors = [];
          if (res.status == 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Password Reset',
              detail: res.data.message,
              life: 3000,
            });
            this.$router.push({ path: '/login' });
          }
        })
        .catch((error) => {
          this.errors = [];
          if (error.status == 422) {
            if (error.data.errors.password && error.data.errors.password.includes('The given password has appeared in a data leak. Please choose a different password.')) {
              this.$toast.add({
                severity: 'error',
                summary: 'Please Choose Different Password',
                detail: 'It looks like that password is a weak password.',
                life: 10000,
              });
            } else {
              for (const key in error.data.errors) {
                error.data.errors[key].forEach((element) => {
                  this.errors.push(element);
                });
              }
            }
          } else {
            this.showGenericErrorToast();
          }
        });
    },
  },
  mounted() {
    this.toggleTransition = true;
  },
};
</script>
<style>
.password-strength-indicator {
  margin-bottom: 1rem;
}
</style>
