<template>
  <div class="flex flex-col w-full">
    <div v-if="addendums.length > 0" class="text-sm font-bold font-display text-start w-full">Addendums</div>
    <div class="flex flex-col">
      <div v-for="(addendum, index) in addendums" :key="addendum.id" class="flex flex-row w-full my-2">
        <div class="border border-gray-200 text-left font-display text-sm w-full">
          <div class="text-xs bg-gray-200 p-2">{{ formatDateTime(addendum.created_at) }} {{ getUserTimeZoneAbbr() }}</div>
          <div class="text-left p-3" :id="addendumTextId(index)">
            <span v-if="addendum.text.length < 500 || addendumTextExpanded[index] == true">
              {{ addendum.text }}
              <span v-if="addendumTextExpanded[index]" class="text-primary cursor-pointer" @click="collapseAddendumText(index)"> See less</span>
            </span>
            <span v-else>
              {{ addendum.text.substring(0, 300) }}...
              <span class="text-primary cursor-pointer" @click="expandAddendumText(index)">See more</span>
            </span>
          </div>
          <div v-for="(file, i) in addendum.attached_files" @click="downloadAttachedFile({ id: file.id, user_id: actingUser.id })" class="flex flex-row border rounded m-2 p-2 justify-start items-center text-sm hover:bg-gray-100 cursor-pointer">
            <i class="pi pi-paperclip mr-2"></i>
            <span class="w-full mr-1" :id="fileNameId(index, i)">{{ file.original_file_name }}</span>
            <div class="flex flex-row justify-end cursor-pointer">
              <i class="pi pi-download mr-2 text-primary"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-2 text-left w-full" v-if="openAddendum">
      <TextWithFileUploadInputComponent :id="inputId()" :title="'New Addendum'" @submittedForm="addAddendum" @closedComponent="openAddendum = !openAddendum" />
    </div>
    <div class="mt-3 flex flex-row justify-end">
      <Button name="makeAddendumButton" icon="pi pi-pencil" label="Make Addendum" class="response-buttons" @click="openAddendum = !openAddendum" v-if="showMakeAddendumButton" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';

export default {
  name: 'AddendumsComponent',
  props: ['type', 'referral', 'addendums', 'responseForm'],
  emits: ['submittedForm', 'closedComponent'],
  data() {
    return {
      openAddendum: false,
      addendumTextExpanded: {},
    };
  },
  components: {
    Button,
    Textarea,
  },
  computed: {
    ...mapGetters(['loggedInUser', 'actingUser', 'referralAddendums', 'attachedReferrals']),
    showMakeAddendumButton() {
      if (this.type == 'referral_response_form') {
        return !this.openAddendum && this.responseForm.submitted && this.loggedInUser.id == this.referral.referral_person.id;
      } else if (this.type == 'referral_form') {
        return !this.openAddendum && (this.referral.status === 'Upcoming' || this.referral.status === 'In Review') && this.loggedInUser.id === this.referral.referee.id && (this.referral.interaction_type.name === 'phone' || this.referral.interaction_type.name === 'econsult');
      } else if (this.type == 'encounter_note') {
        return !this.openAddendum && ['Signed Off', 'Complete'].includes(this.referral.status);
      }
      return false;
    },
  },
  methods: {
    ...mapActions(['createReferralAddendum', 'downloadAttachedFile']),
    addAddendum(form) {
      this.isLoading = true;
      this.$toast.removeAllGroups();
      form.append('user_id', this.loggedInUser.id);
      form.append('referral_id', this.referral.id);
      form.append('type', this.type);
      this.isLoading = true;
      this.$toast.removeAllGroups();
      this.createReferralAddendum(form)
        .then(() => {
          this.addendumText = '';
          this.openAddendum = false;
          this.isLoading = false;
          this.$toast.add({
            severity: 'success',
            summary: 'Addendum Added',
            life: 5000,
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    inputId() {
      if (this.type == 'referral_response_form') {
        return 'referralResponseFormAddendumInput';
      } else if (this.type == 'referral_form') {
        return 'referralFormAddendumInput';
      } else if (this.type == 'encounter_note') {
        return 'encounterAddendumInput';
      }
    },
    fileNameId(index, i) {
      if (this.type == 'referral_response_form') {
        return 'referralResponseFormAddendum' + index + 'File' + i + 'Name';
      } else if (this.type == 'referral_form') {
        return 'referralFormAddendum' + index + 'File' + i + 'Name';
      } else if (this.type == 'encounter_note') {
        return 'encounterAddendum' + index + 'File' + i + 'Name';
      }
    },
    addendumTextId(index) {
      if (this.type == 'referral_response_form') {
        return 'referralResponseFormAddendumText' + index;
      } else if (this.type == 'referral_form') {
        return 'referralFormAddendumText' + index;
      } else if (this.type == 'encounter_note') {
        return 'encounterAddendumText' + index;
      }
    },
    expandAddendumText(index) {
      this.addendumTextExpanded[index] = true;
    },
    collapseAddendumText(index) {
      this.addendumTextExpanded[index] = false;
    },
  },
  created() {},
};
</script>
